import Box from '@mui/material/Box'
import MuiTooltip, { type TooltipProps } from '@mui/material/Tooltip'
import { type SxProps } from '@mui/material/styles'

type Props = TooltipProps & { contentSx?: SxProps }

export default function Tooltip({ placement = 'right', arrow = true, slotProps, children, contentSx, ...rest }: Props) {
    return (
        <MuiTooltip
            placement={placement}
            arrow={arrow}
            slotProps={{
                tooltip: {
                    sx: {
                        p: 3,
                        bgcolor: 'newNeutral.main',
                        color: '#fff',
                        typography: 'newLabelSm'
                    }
                },
                arrow: { sx: { color: 'newNeutral.main' } },
                ...slotProps
            }}
            {...rest}
        >
            <Box sx={{ ...contentSx }}>{children}</Box>
        </MuiTooltip>
    )
}
