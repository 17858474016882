// ** react imports
import { FC, forwardRef } from 'react'

// ** react router imports
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

// ** mui imports
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import Loader from '@mui/material/CircularProgress'

// ** custom component imports
import Icon, { IconProps } from 'src/@core/components/icon'

// ** hooks imports
import { useSettings } from 'src/@core/hooks/useSettings'

// ** utils imports
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

type UselessButtonProps = 'variant'
type CustomButtonProps = {
    loading?: boolean
    tooltip?: string
    TooltipProps?: Omit<TooltipProps, 'children' | 'title'>
}

type PrimitiveIconButtonProps = Pick<IconProps, 'icon' | 'fontSize'>
type CustomIconButtonProps = PrimitiveIconButtonProps &
    CustomButtonProps & {
        href?: RouterLinkProps['to']
        target?: RouterLinkProps['target']
        tooltip?: string
        IconProps?: Omit<IconProps, 'icon' | 'fontSize'>
        TooltipProps?: TooltipProps
    }

type ButtonProps = Omit<MuiButtonProps, UselessButtonProps> & CustomButtonProps
type IconButtonProps = MuiIconButtonProps & CustomIconButtonProps

export type ButtonComponentProps =
    | ({ variant?: MuiButtonProps['variant'] } & ButtonProps)
    | ({ variant?: 'icon' } & IconButtonProps)

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
    (props, ref) => {
        const { href, ...other } = props

        return <RouterLink ref={ref} to={href} {...other} />
    }
)

const Button: FC<ButtonComponentProps> = ({ loading = false, children, TooltipProps, ...props }) => {
    // ** hooks
    const { settings } = useSettings()

    // ** methods
    const renderButton = () => {
        const { startIcon, endIcon, ...buttonProps } = props as MuiButtonProps

        return (
            <MuiButton
                type='button'
                variant='contained'
                color='primary'
                LinkComponent={LinkBehavior}
                {...buttonProps}
                {...(startIcon && !loading && { startIcon })}
                {...(endIcon && !loading && { endIcon })}
                disabled={loading || props.disabled}
                sx={{
                    minWidth: 'max-content !important',
                    fontSize: 12,
                    ...((props.color === 'primary' || !props.color) && {
                        '&:hover': {
                            bgcolor: (theme) => hexToRGBA(theme.palette[settings.themeColor].main, 0.8)
                        }
                    }),
                    ...props.sx
                }}
            >
                {loading ? (
                    <Loader
                        size={20}
                        sx={{
                            color: (theme) =>
                                props.variant != 'contained' ? theme.palette[settings.themeColor].main : '#FFF'
                        }}
                    />
                ) : (
                    children
                )}
            </MuiButton>
        )
    }

    const renderIconButton = () => {
        const { icon, fontSize, href, IconProps, ...iconProps } = props as IconButtonProps

        return (
            <MuiIconButton
                size='small'
                {...(href && {
                    LinkComponent: LinkBehavior,
                    href
                })}
                {...iconProps}
            >
                {children || <Icon icon={icon} fontSize={fontSize || 20} {...IconProps} />}
            </MuiIconButton>
        )
    }

    return props.tooltip && (!props.disabled || loading) ? (
        <Tooltip title={props.tooltip} placement='top-start' {...TooltipProps}>
            {props.variant == 'icon' ? renderIconButton() : renderButton()}
        </Tooltip>
    ) : props.variant == 'icon' ? (
        renderIconButton()
    ) : (
        renderButton()
    )
}

export default Button
