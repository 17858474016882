import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import Checkbox from '@mui/material/Checkbox'
import {
    useGridApiRef,
    type GridApiPro,
    type GridColDef,
    type GridFilterModel,
    type GridPaginationModel,
    type GridRowSelectionModel
} from '@mui/x-data-grid-pro'
// import DataTable from 'src/components/shared/DataTable'
import Container from 'src/components/shared/Container'
import BaseMuiDataGrid from 'src/components/shared/BaseMuiDataGrid'
import Textfield from 'src/components/shared/Textfield'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import CircularProgress from 'src/components/shared/CircularProgress'
import AddAction from 'src/components/new-enrichment/enrich/AddAction'
import DeleteDialog from 'src/components/shared/DeleteDialog'
import TickLoader from 'src/components/shared/TickLoader'
import { getBulk, deleteBulkRows } from 'src/services/new-enrichment'
import {
    connect as socketConnect,
    disconnect as socketDisconnect,
    emitGetBulk,
    onBulkUpdate
} from 'src/services/socket/enrichment'
import { generateExcel, downloadFile } from 'src/utils/file'
import { type Bulk, Status } from 'src/types/enrichment'

type Row = {
    _id: string
    rowNum: number
    [key: string]: unknown
}
type Props = BoxProps & { bulkId: number }

const intl = Intl.NumberFormat('en-US')

export default function EnrichTable({ bulkId, ...rest }: Props) {
    const navigate = useNavigate()
    const tableRef = useGridApiRef()
    const [filters, setFilters] = useState<GridFilterModel>({ items: [], quickFilterValues: [''] })
    const [bulk, setBulk] = useState<null | Bulk>(null)
    // const [pagination, setPagination] = useState<GridPaginationModel>({ page: 1, pageSize: 3 })
    const [selections, setSelections] = useState<GridRowSelectionModel>([])
    const [bulkLoading, setBulkLoading] = useState(false) //base bulk file loading
    const [enrichLoading, setEnrichLoading] = useState(false) //when we try to enrich bulk file
    const [processingColumns, setProcessingColumns] = useState<string[]>([])
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showEnrichForm, setShowEnrichForm] = useState(false)
    const currColsCount = useRef(0) //hold latest counts of columns ... useful for times we want to scroll table to the right
    // const {
    //     isFetching: bulkLoading,
    //     data: bulk,
    //     refetch: getBulkReq
    // } = useQuery({
    //     initialData: null,
    //     queryKey: ['get-bulk', bulkId],
    //     // refetchInterval: 5000,
    //     queryFn: async () => {
    //         const bulk = await getBulk({ id: bulkId })
    //         return bulk
    //     }
    // })
    const headers = bulk ? bulk.cellsDataNormalize[0] : []
    const hasSelections = !!selections.length
    const totalCounts = bulk?.cellsData.length || 0
    const rows = useMemo<Row[]>(() => {
        return bulk?.cellsData.map((data, i) => ({ ...data, rowNum: i + 1 })) || []
    }, [bulk])
    const columns = useMemo<GridColDef<Row>[]>(() => {
        const result: GridColDef[] = []
        headers.forEach((header) => {
            const headerStr = `${header}`
            const isProcessing =
                enrichLoading && Array.isArray(processingColumns) && processingColumns.includes(headerStr)
            result.push({
                field: headerStr,
                headerName: headerStr,
                width: 250,
                headerClassName: isProcessing ? 'loader-header' : '', //will be apply on .MuiDataGrid-columnHeader
                cellClassName: isProcessing ? 'loader-cell' : '', //will be apply on .MuiDataGrid-cell
                renderHeader: ({ colDef: { headerName } }) => {
                    return (
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                            <Typography variant='newLabelMd' color='newNeutral' textTransform='lowercase'>
                                {headerName}
                            </Typography>
                            {isProcessing && <TickLoader size={12} width={1} height={6} />}
                        </Stack>
                    )
                },
                renderCell: ({ value }) => <p className='line-clamp-1'>{value}</p> //we don't use <Typography /> so we keep default table cell text styles
            })
        })
        return result
    }, [rows, headers, selections])
    const scrollTable = ({ rowIndex, colIndex }: { rowIndex?: number; colIndex?: number }) => {
        //we define separated custom component for it and we call it only in locations that we need it
        const table = tableRef.current
        if (table) {
            setTimeout(() => {
                //use setTimeout to wait for next render and table gets fully updated
                table.scrollToIndexes({ rowIndex, colIndex })
            }, 100)
        }
    }
    const emitBulk = async () => {
        setBulkLoading(true)
        try {
            emitGetBulk(
                {
                    id: bulkId
                },
                (bulk) => {
                    if (!bulk.isExist) throw new Error('Not Found !')
                    else if (bulk.id !== bulkId) {
                        setBulk(null)
                        setBulkLoading(false)
                    } else {
                        setBulk(bulk)
                        setBulkLoading(false)
                        setProcessingColumns(bulk.processingColumns)
                        setEnrichLoading(bulk.status === Status.PENDING)
                    }
                }
            )
        } catch (err) {
            navigate('*')
        }
    }
    const { isPending: deleteLoading, mutateAsync: deleteRows } = useMutation({
        mutationFn: async () => {
            await deleteBulkRows({ bulkId, rowIds: selections as string[] })
        },
        onSuccess: async () => {
            // const { page, pageSize } = pagination
            setSelections([])
            setShowDeleteDialog(false)
            // if (totalCounts - selections.length <= (page - 1) * pageSize) {
            //     setPagination((old) => ({ ...old, page: old.page - 1 }))
            // }
            emitBulk()
        }
    })
    const downloadFileInfos = async () => {
        setDownloadLoading(true)
        const blob = await generateExcel({
            data: bulk?.cellsDataNormalize || [],
            colsWidth: headers.map((head) => (`${head}`.includes('description') ? 80 : 20))
        })
        downloadFile({ blob, filename: bulk!.name, extension: 'xlsx' })
        setDownloadLoading(false)
    }
    useEffect(() => {
        currColsCount.current = columns.length
    }, [columns.length])
    useEffect(() => {
        // only on first render connect to socket and disconnect when we unmount component
        socketConnect(() => {
            emitBulk() //emit to get initial data
            try {
                //list to socket events
                onBulkUpdate((newBulk) => {
                    //we don't use setLoading(true) here and we should set it before any action that can trigger socket .on methods ... here we use it inside onSubmit of <AddAction />
                    const newColsCount = newBulk.cellsDataNormalize[0].length
                    if (!newBulk.isExist) throw new Error('Not Found !')
                    else if (newBulk.id !== bulkId) {
                        setBulk(null)
                        setEnrichLoading(false)
                    } else {
                        setBulk(newBulk)
                        setProcessingColumns(newBulk.processingColumns)
                        setEnrichLoading(newBulk.status === Status.PENDING)
                        if (currColsCount.current !== newColsCount) {
                            //only scroll table if we update columns
                            scrollTable({ colIndex: newBulk.cellsDataNormalize[0].length }) //columns will be generated from first cell of bulk.cellsDataNormalize so bulk.cellsDataNormalize[0].length will be last cell
                        }
                    }
                })
            } catch (err) {
                navigate('*')
            }
        })
        return () => {
            socketDisconnect()
        }
    }, [])

    return (
        <Box {...rest}>
            <Container bgcolor='white' p={3} border={1} borderRadius={1} borderColor='newNeutral.light5'>
                <Box>
                    {!hasSelections && (
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            flexWrap='wrap'
                            gap={3}
                        >
                            <Textfield
                                variant='outlined'
                                size='xs'
                                color='newEnrichment'
                                borderColor='newNeutral.light4'
                                value={filters.quickFilterValues}
                                onChange={(e) =>
                                    setFilters((old) => ({ ...old, quickFilterValues: [e.target.value || ''] }))
                                }
                                prependInnerIcon='tdesign:search'
                                placeholder='Search'
                                bgcolor='white'
                                fullWidth={false}
                                sx={{ width: { xs: 1, lg: 250 } }}
                            />
                            <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                                <Button
                                    disabled={!bulk}
                                    // loading={downloadLoading}
                                    loadingColor='newPrimary'
                                    variant='text'
                                    size='xs'
                                    sx={{ bgcolor: 'white', minWidth: 0 }}
                                    onClick={() => downloadFileInfos()}
                                    className='shadow-2'
                                >
                                    <Icon icon='tdesign:download' color='newNeutral' size='md' />
                                </Button>
                                <Button
                                    variant='contained'
                                    color='newNeutral'
                                    size='xs'
                                    sx={{ px: 5, typography: 'subtitle2', color: 'white' }}
                                    onClick={() => setShowEnrichForm(true)}
                                >
                                    <Icon icon='subway:power' color='white' size='sm' className='mr-2' />
                                    Enrich
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    {hasSelections && (
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            flexWrap='wrap'
                            gap={3}
                        >
                            <Typography variant='newLabelLg' color='newNeutral'>
                                {selections.length} {selections.length > 1 ? 'Items' : 'Item'} Selected
                            </Typography>
                            <Button
                                loading={downloadLoading}
                                variant='outlined'
                                color='newError'
                                size='xs'
                                onClick={() => setShowDeleteDialog(true)}
                            >
                                <Icon icon='mdi:delete-outline' color='newError' size='md' className='mr-2' />
                                Delete
                            </Button>
                        </Stack>
                    )}
                </Box>
                <Box mt={3} height={'75vh'}>
                    <BaseMuiDataGrid
                        apiRef={tableRef}
                        loading={bulkLoading}
                        color='newEnrichment'
                        checkboxColor='newEnrichment'
                        getRowId={(row) => row._id}
                        columns={columns}
                        rows={rows}
                        columnHeaderHeight={40}
                        getRowHeight={(row) => 40}
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        filterMode='client'
                        filterModel={filters}
                        onFilterModelChange={(newVal) => setFilters(newVal)}
                        paginationMode='client'
                        pagination={false}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowSelectionModel={selections}
                        onRowSelectionModelChange={(newVal) => setSelections(newVal)}
                        hideFooter
                        slots={{
                            footer: (props) => (
                                <Box {...props} p={3} borderTop={1} borderColor='newNeutral.light5'>
                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        gap={2}
                                        flexWrap='wrap'
                                    >
                                        <Stack direction='row' alignItems='center' gap={2} flexWrap='wrap'>
                                            {enrichLoading && (
                                                <Stack direction='row' alignItems='center' gap={2}>
                                                    <CircularProgress variant='indeterminate' size={15} thickness={2} />
                                                    <Typography variant='newLabelMd' color='newNeutral.light3'>
                                                        Updating Cells ...
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                        <Typography component='p' variant='newBodySm' color='newNeutral'>
                                            Total Row: {intl.format(rows.length)}
                                        </Typography>
                                    </Stack>
                                </Box>
                            )
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                minHeight: 'auto !important',
                                maxHeight: 'auto !important',
                                '& .MuiDataGrid-columnHeader': {
                                    bgcolor: 'newSurface.light1',
                                    p: 0
                                }
                            },
                            '& .MuiDataGrid-virtualScrollerContent': {
                                '& .MuiDataGrid-row': {
                                    typography: 'newBodySm',
                                    color: 'newNeutral.light2'
                                    // minHeight: '52px !important'
                                }
                            },
                            '& .loader-header,.loader-cell': {
                                bgcolor: 'newPrimary.light5'
                            },
                            '& .MuiCheckbox-root:not(.Mui-checked) svg': {
                                color: 'newNeutral.light5'
                            }
                        }}
                    />
                </Box>
                <DeleteDialog
                    open={showDeleteDialog}
                    onOpenChange={(val) => setShowDeleteDialog(val)}
                    loading={deleteLoading}
                    onDelete={() => deleteRows()}
                />
                <Drawer
                    anchor='right'
                    open={showEnrichForm}
                    onClose={() => setShowEnrichForm(false)}
                    PaperProps={{ sx: { width: 540, maxWidth: '90vw' } }}
                >
                    <AddAction
                        bulkId={bulkId}
                        closable
                        onClose={() => setShowEnrichForm(false)}
                        onSubmit={() => {
                            setEnrichLoading(true)
                            setShowEnrichForm(false)
                        }}
                    />
                </Drawer>
            </Container>
            <Stack
                p={3}
                mt={4}
                position='fixed'
                bottom={0}
                left={0}
                width={1}
                zIndex={10}
                bgcolor='newSurface.light3'
                borderTop={1}
                borderColor='newNeutral.light5'
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                gap={4}
            >
                <Stack direction='row' alignItems='center' gap={2} flexWrap='wrap'>
                    {enrichLoading && (
                        <Stack direction='row' alignItems='center' gap={2}>
                            <TickLoader size={12} width={1} height={6} color='newNeutral' />
                            <Typography variant='newLabelMd' color='newNeutral'>
                                Updating Table...
                            </Typography>
                        </Stack>
                    )}
                    {!enrichLoading && (
                        <Typography variant='newLabelMd' color='newNeutral'>
                            {intl.format(totalCounts)} rows total
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}
