// ** models imports
import { ListItemModel, ListModel, TeamModel, UserLogModel } from 'src/shared/models'

export enum TeamStatus {
    OWNER = -1,
    PENDING = 0,
    ACCEPTED,
    REJECTED
}

type TeamMemberModel = Pick<TeamModel, 'status'> & {
    id: number
    name: string
    email: string
    month_credits: number
    annual_credits: number
    is_team_head: boolean
    invite_date: string
}

type TeamMemberSavedListParams = {
    memberEmail: string
    start: number
    limit: number
}

type ExportTeamMemberListsParams = {
    memberEmail: string
    listId: number
}

type TeamMemberSearchLogsParams = {
    memberEmail: string
    start: number
    limit: number
}

type InvitationModel = {
    team_id: string
    inviteeName: string
    inviteeEmail: string
}

type TeamMembersListApiResponse = {
    list: Array<TeamMemberModel>
}

type InvitationsListApiResponse = {
    invitations: Array<InvitationModel>
}

type TeamMemberSavedListsApiResponse = {
    lists: Array<ListModel>
}

type TeamMemberSearchLogsApiResponse = {
    logs: Array<UserLogModel>
    total_count: number
}

type TeamMemberExportListApiResponse = {
    items: Array<ListItemModel>
}

export type {
    TeamMemberModel,
    TeamMemberSavedListParams,
    ExportTeamMemberListsParams,
    TeamMemberSearchLogsParams,
    InvitationModel,
    TeamMembersListApiResponse,
    InvitationsListApiResponse,
    TeamMemberSavedListsApiResponse,
    TeamMemberSearchLogsApiResponse,
    TeamMemberExportListApiResponse
}
