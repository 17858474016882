import MuiLink, { type LinkProps } from '@mui/material/Link'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Casing = 'lowercase' | 'capitalize' | 'uppercase'
export type Props = Omit<LinkProps, 'size'> & {
    size?: Size
    casing?: Casing
}

export default function Button({
    variant = 'newLabelLg', //variant here is not contains,outlined,... and its different from button
    size = 'md',
    color = 'primary',
    casing = 'capitalize',
    children,
    className = '',
    sx,
    ...rest
}: Props) {
    return (
        <MuiLink
            color={color}
            className={className}
            sx={{
                typography: 'newLabelLg',
                textAlign: 'center',
                boxShadow: 0,
                borderRadius: 1,
                textTransform:
                    casing === 'lowercase' ? 'lowercase' : casing === 'capitalize' ? 'capitalize' : 'uppercase',
                p: (theme) => {
                    switch (size) {
                        case 'xs':
                            return `${theme.spacing(2)} ${theme.spacing(2)}`
                        case 'sm':
                            return `${theme.spacing(3)} ${theme.spacing(3)}`
                        case 'md':
                            return `${theme.spacing(5)} ${theme.spacing(4)}`
                        case 'lg':
                            return `${theme.spacing(6)} ${theme.spacing(5)}`
                        case 'xl':
                            return `${theme.spacing(7)} ${theme.spacing(6)}`
                        default:
                            return `${theme.spacing(5)} ${theme.spacing(4)}`
                    }
                },
                ...sx
            }}
            {...rest}
        >
            {children}
        </MuiLink>
    )
}

//? Example:
{
    /* <Link href={link} target='_blank'
    sx={{
        mt: 5,
        display:'block',
        width: 1,
        borderRadius: 1,
        py: 3,
        bgcolor: 'newSurface.light',
        color: 'newNeutral.light2',
        border: 1,
        borderColor: 'newSurface.main'
    }}
>
    Learn More About This Service
</Link> */
}
