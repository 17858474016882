// ** types import
import type { HorizontalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): HorizontalNavItemsType => {
    return [
        {
            icon: 'mdi:account-cog-outline',
            title: 'Enrichment Engine',
            path: '/enrichment/bulks'
        },
        {
            icon: 'mdi:target-account',
            title: 'Prospect Engine',
            path: '/prospect/contact',
            action: 'menu',
            subject: 'prospect-engine'
        },
        {
            icon: 'fluent:task-list-square-person-20-regular',
            title: 'Create B2B List',
            path: '/business-directory/search'
        }
    ]
}

export default navigation
