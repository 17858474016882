import { type CSSProperties } from 'react'
import Box, { type BoxProps } from '@mui/material/Box'
import styles from './styles.module.scss'
import useColor from 'src/hooks/useColor'

type Props = BoxProps & {
    size?: number
    width?: number
    height?: number
    color?: string
}

export default function TickLoader({
    size = 25, //(size * 2) will be width/height of main container
    width = 2, //width of each bar
    height = 12, //height of each bar
    color = 'newPrimary',
    ...rest
}: Props) {
    const parsedColor = useColor(color)

    return (
        <Box {...rest}>
            <div
                className={styles.spinner}
                style={
                    {
                        '--size': `${size}px`,
                        '--width': `${width}px`,
                        '--height': `${height}px`,
                        '--color': parsedColor
                    } as CSSProperties
                }
            >
                {Array.from({ length: 12 }).map((_, i) => (
                    <div key={i} />
                ))}
            </div>
        </Box>
    )
}
