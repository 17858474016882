import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import AutoComplete, { type Props as AutoCompleteProps, type Option } from 'src/components/shared/AutoComplete'
import Icon from 'src/components/shared/Icon'
import { countriesMap } from 'src/utils/country'

type Props = Omit<AutoCompleteProps<Option, true, false, false>, 'options' | 'renderOption' | 'renderTags'> & {}

const CountrySelect = ({ onChange, ...rest }: Props) => {
    const options: Option[] = Array.from(countriesMap).map((country) => ({ value: country[0], label: country[1] }))

    return (
        <AutoComplete
            multiple //? we use multiple because renderTags only work for multiple:true
            onChange={(e, newVal) => {
                //renderTags need multiple:true so we do bellow code to always have one cell in our value array
                const lastOption = newVal.at(-1) || null
                if (onChange) onChange(e, lastOption ? [lastOption] : [])
            }}
            options={options}
            filterSelectedOptions={false}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => {
                const { key, ...rest } = props
                return (
                    <Box key={key} {...rest} component='li'>
                        <Icon icon={`flag:${option.value}-4x3`} size='sm' className='mr-2' />
                        {option.label}
                    </Box>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((val, index) => {
                    const { key, ...rest } = getTagProps({ index })
                    return (
                        <Chip
                            key={key}
                            {...rest}
                            size='medium'
                            label={
                                <>
                                    <Icon icon={`flag:${val.value}-4x3`} size='sm' className='mr-2 -mb-1' />
                                    {val.label}
                                </>
                            }
                            onClick={undefined} //remove clickable ability
                            onDelete={undefined} //remove close icon from chip
                            sx={{ bgcolor: 'transparent' }}
                        />
                    )
                })
            }
            slotProps={{
                paper: { sx: { '& .MuiAutocomplete-option': { py: 3 } } }
            }}
            {...rest}
        />
    )
}

export default CountrySelect

//? Example:
//we use array but we always get one cell in array so for reading value we can use country[0]
// const { control } = useForm<{country:Option[]}>({defaultValues: {country: []}})
// <Controller control={control} name='val' rules={{ required: 'msg' }}
//     render={({ field, fieldState }) => (
//         <CountrySelect value={field.value} onChange={(_, v) => field.onChange(v)} //we don't use forward ref so we don't use {...field} and just manually set value,onChange
//             label='label' error={!!fieldState.error} helperText={fieldState.error?.message} clearable
//         />
//     )}
// />
