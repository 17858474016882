import Box, { type BoxProps } from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

type Props = BoxProps & {}

export default function Container({ children, ...rest }: Props) {
    const theme = useTheme()
    const desktopMq = theme.breakpoints.up(2000)

    return (
        <Box
            mx='auto'
            sx={{
                width: { xs: '97%' },
                [desktopMq]: { width: 1920 }
            }}
            {...rest}
        >
            {children}
        </Box>
    )
}
