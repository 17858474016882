// ** types imports
import { PaginationParams } from 'src/shared/types/services'

// ** third party imports
import { EditorState } from 'react-draft-wysiwyg'

type EmailType = 'IMAP' | 'SMTP'

export enum CampaignStatus {
    PAUSED = 0,
    ACTIVE
}

export enum LeadAcitivityStatus {
    SENT = 2,
    OPENED,
    REPLIED
}

export enum EmailAccountType {
    GOOGLE = 1,
    MICROSOFT,
    CUSTOM
}

export enum CampaignEmailStatus {
    ACTIVE = 1,
    PAUSED,
    DISCONNECTED
}

type CampaignSenderType = {
    label: string
    isActive: boolean
    value: string
}

type CampaignScheduleModel = {
    order: number
    name: string
    start_time: string
    end_time: string
    timezone: string
    days: Array<string>
}

type CampaignStepModel = {
    order: number
    subject: string
    content: EditorState | null
    offset: number
}

type CampaignOptionsModel = {
    senders: Array<CampaignSenderType>
    stopOnReply: boolean
    stopOnAutoreply: boolean
    openTracking: boolean
    linkTracking: boolean
    textOnly: boolean
    dailyLimit: number
    timeGapMin?: number
    timeGapMax?: number
    maxNewLeads?: number
    cc: Array<string>
    bcc: Array<string>
}

type NewCampaignModel = CampaignOptionsModel & {
    name: string
    status: CampaignStatus
    start_date: Date | null
    end_date?: Date
    schedules: Array<CampaignScheduleModel>
    steps: Array<CampaignStepModel>
}

type CampaignModel = Omit<NewCampaignModel, 'steps'> & {
    steps: Array<Omit<CampaignStepModel, 'content'> & { content: string }>
}

type CampaignsListApiResponseModel = {
    campaigns: Array<CampaignModel>
    total_count: number
}

type AllCampaignsApiResponseModel = {
    campaigns: Array<{ id: number; name: string }>
}

type AddNewLeadToCampaignsParams = {
    campaignIds: Array<number>
    contactId: string
}

type AddNewLeadsToCampaignParams = {
    campaignId: string
    contactsId: Array<string>
}

type NewEmailParams = {
    email: string
    type: 'google' | 'microsoft' | 'custom'
    first_name: string
    last_name: string
    imap_username: string
    imap_password: string
    imap_host: string
    imap_port: string
    smtp_username: string
    smtp_password: string
    smtp_host: string
    smtp_port: string
}

type SearchEmailsParams = PaginationParams & {
    searchedTxt: string
}

type UpdateEmailParams = NewEmailParams & {
    id: string
}

type SendPreviewEmailParams = {
    senderEmailId: number
    receiverEmail: string
    subject: string
    content: string
}

type VerifyIMAPSMTPEmailParams = {
    username: string
    password: string
    host: string
    port: number
    type: EmailType
}

type EmailAccountModel = {
    email: string
    first_name: string
    last_name: string
    status: number
    created_at: string
}

type CampaignAnalyticsParams = {
    campaignId: number
    type: 'day' | 'month' | 'custom'
    period?: string
    start_date?: string
    end_date?: string
}

type CampaignActivitiesParams = PaginationParams & {
    campaignId: number
}

type SearchCampaignActivitiesParams = PaginationParams & {
    campaignId: number
    searchedTxt: string
    status?: LeadAcitivityStatus
}

type SearchLeadsParams = PaginationParams & {
    campaignId: number
    searchedTxt: string
}

type UpdateStepsParams = {
    name: string
    steps: Array<any>
}

type UpdateSchedulesParams = {
    schedules: Array<any>
    start_date: Date | null
    end_date?: Date
}

type CampaignAnalyticsModel = {
    min_sent_emails_count: number
    min_opened_emails_count: number
    min_opened_emails_percent: number
    min_clicked_emails_count: number
    min_clicked_emails_percent: number
    min_replied_emails_count: number
    min_replied_emails_percent: number
}

type CampaignStepsAnalyticsModel = {
    name: string
    sent_count: number
    opened_count: number
    replied_count: number
    clicked_count: number
}

type CampaignAcitivityLogModel = {
    id: string
    email: string
    step: number
    status: LeadAcitivityStatus
    created_at: string
}

type CampaignAnalyticsChartReportsModel = {
    days: Array<string>
    sent_count_reports: Array<number>
    total_opens_count_report: Array<number>
    unique_opens_count_report: Array<number>
    total_replied_count_report: Array<number>
    total_clicks_count_report: Array<number>
    unique_clicks_count_report: Array<number>
}

type EmailInfoModel = Omit<NewEmailParams, 'imap_password' | 'smtp_password' | 'imap_port' | 'smtp_port' | 'type'> & {
    type: EmailAccountType
    status: CampaignEmailStatus
    imap_port: number
    smtp_port: number
    created_at: string
}

type CampaignLeadModel = {
    email: string
    created_at: string
}

type NewCampaignParams = Omit<NewCampaignModel, 'senders' | 'status'> & {
    senders: Array<string>
}

type CampaignInfoApiResponse = {
    campaign: CampaignModel
}

type CampaignAnalyticsApiResponse = {
    analytics: CampaignAnalyticsModel
    steps_analytics: Array<CampaignStepsAnalyticsModel>
    chart_reports: CampaignAnalyticsChartReportsModel
}

type CampaignActivitiesApiResponse = {
    activities: Array<CampaignAcitivityLogModel>
}

type CampaignLeadsApiResponse = {
    leads: Array<CampaignLeadModel>
}

type EmailsListApiResponse = {
    emails: Array<EmailAccountModel>
    total_count: number
}

type EmailInfoApiResponse = {
    email_info: EmailInfoModel
}
type EmailAccountsApiResponse = {
    accounts: Array<Pick<EmailAccountModel, 'email' | 'status'> & { id: number }>
}

type VerifyEmailApiResponseModel = {
    is_verified: boolean
}

export type {
    CampaignModel,
    CampaignScheduleModel,
    CampaignStepModel,
    CampaignOptionsModel,
    NewCampaignModel,
    EmailType,
    CampaignsListApiResponseModel,
    AllCampaignsApiResponseModel,
    AddNewLeadToCampaignsParams,
    AddNewLeadsToCampaignParams,
    NewEmailParams,
    SearchEmailsParams,
    UpdateEmailParams,
    SendPreviewEmailParams,
    VerifyIMAPSMTPEmailParams,
    EmailAccountModel,
    CampaignAnalyticsParams,
    CampaignActivitiesParams,
    SearchCampaignActivitiesParams,
    SearchLeadsParams,
    UpdateStepsParams,
    UpdateSchedulesParams,
    CampaignAnalyticsModel,
    CampaignStepsAnalyticsModel,
    CampaignAcitivityLogModel,
    CampaignAnalyticsChartReportsModel,
    EmailInfoModel,
    CampaignLeadModel,
    NewCampaignParams,
    CampaignInfoApiResponse,
    CampaignAnalyticsApiResponse,
    CampaignActivitiesApiResponse,
    CampaignLeadsApiResponse,
    EmailsListApiResponse,
    EmailInfoApiResponse,
    EmailAccountsApiResponse,
    VerifyEmailApiResponseModel
}
