import { Outlet } from 'react-router-dom'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import Box from '@mui/material/Box'
import Nav from 'src/components/new-enrichment/Nav'

export default function EnrichmentLayout() {
    return (
        <AuthGuard>
            <Box>
                <Nav />
                <Box my={6}>
                    <Outlet />
                </Box>
            </Box>
        </AuthGuard>
    )
}
