// ** React Import
import { useEffect, useRef } from 'react'

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'

// ** Layout Components
import VerticalLayout from './VerticalLayout'
import HorizontalLayout from './HorizontalLayout'

const Layout = (props: LayoutProps) => {
    // ** Props
    const { hidden, children, settings, saveSettings } = props

    useEffect(() => {
        saveSettings({ layout: hidden ? 'vertical' : 'horizontal' })
    }, [hidden])

    if (settings.layout === 'horizontal') {
        return <HorizontalLayout {...props}>{children}</HorizontalLayout>
    }

    return <VerticalLayout {...props}>{children}</VerticalLayout>
}

export default Layout
