// ** react imports
import { FC } from 'react'

// ** react router imports
import { Navigate } from 'react-router-dom'

const DefaultScreen: FC = () => {
    return <Navigate to='/enrichment/bulks' replace />
}

export default DefaultScreen
