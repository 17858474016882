import socketManager from './manager'
import authConfigs from 'src/configs/auth'
import { bulkResponseToClient } from 'src/utils/transforms/new-enrichment'
import type { SocketBulkActions, SocketBulk, SocketBulkResponse, SocketGetBulkFilter } from 'src/types/enrichment'

export const socket = socketManager.socket('/bulks', {
    auth: (callback) => {
        const token = localStorage.getItem(authConfigs.storageTokenKeyName)
        callback({ token })
    }
})
export const connect = (cb?: () => void) => {
    socket.connect()
    socket.on('connect', () => {
        if (cb) cb()
    })
}
export const disconnect = (cb?: () => void) => {
    socket.disconnect()
    socket.on('disconnect', () => {
        if (cb) cb()
    })
}
export const emitGetBulk = ({ id }: SocketGetBulkFilter, cb: (bulk: SocketBulk) => void) => {
    //use it inside custom function and call that function anywhere we want(inside useEffect or after certain things,...)
    socket.emit<SocketBulkActions>('get_bulk', { bulk_id: id }, (data: SocketBulkResponse) => {
        const bulk = bulkResponseToClient(data.bulk)
        cb({ ...bulk, isExist: data.exists, processingColumns: data.columns || [] })
    })
}
export const onBulkUpdate = (cb: (newBulk: SocketBulk) => void) => {
    //we only define .on 1 time so we should call it after we connect to socket inside useEffect(()=>{},[])
    socket.on<SocketBulkActions>('bulk_info_update', (data: SocketBulkResponse) => {
        const bulk = bulkResponseToClient(data.bulk)
        cb({ ...bulk, isExist: data.exists, processingColumns: data.columns || [] })
    })
}

//?Usage :
// export const customMethod = (payload) => {
//     socket.emit('evt-name',payload)
// }
// Comp.tsx:
// import {socketConnect,socketDisconnect,customMethod} as enrichmentSock from 'src/services/socket/enrichment'
// useEffect(() => {
//     socketConnect()
//     customMethod(payload)
//     return () => {socketDisconnect()}
// }, [])
