import { type ComponentProps, type FormEvent } from 'react'

type FormProps = ComponentProps<'form'>
export type Props = FormProps & {}

export default function Form({
    autoCapitalize = 'off',
    autoComplete = 'off', //in modern browsers autoComplete="off" on <form>,form fields will not work ... instead we should use autoComplete="one-time-code" on each form field
    noValidate = true, //disable browser validation
    onSubmit,
    children,
    ...rest
}: Props) {
    const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit && onSubmit(e)
    }

    return (
        <form
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            noValidate={noValidate}
            onSubmit={onSubmitHandler}
            {...rest}
        >
            {children}
        </form>
    )
}
