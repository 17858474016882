import { RouteObject } from 'react-router-dom'
import TablePage from 'src/pages/enrichment/bulks/[id]'
import OverviewPage from 'src/pages/enrichment/bulks/[id]/Overview'

const routes: Array<RouteObject> = [
    {
        id: 'enrichments-details',
        path: '',
        element: <TablePage />
    },
    {
        id: 'enrichments-overview',
        path: 'overview',
        element: <OverviewPage />
    }
]

export default routes
