import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'

type Props = Omit<DialogProps, 'open' | 'maxWidth'> & {
    open: boolean
    onOpenChange?: (newOpen: boolean) => void
    title?: string
    description?: string
    btnText?: string
    closable?: boolean
    loading?: boolean
    width?: number | string
    maxWidth?: number | string
    onDelete?: () => void
}

export default function DeleteDialog({
    open = false,
    onOpenChange,
    title = 'Delete Item',
    description = 'Are you sure that you want to delete selected item ?',
    btnText = 'Delete',
    closable = true,
    loading = false,
    width = 500,
    maxWidth = '90vw',
    onDelete,
    ...rest
}: Props) {
    const onCloseHandler = () => {
        if (onOpenChange) onOpenChange(false)
    }

    return (
        <Dialog open={open} onClose={onCloseHandler} PaperProps={{ sx: { width, maxWidth, border: 'none' } }} {...rest}>
            <DialogContent sx={{ p: 0 }}>
                <Card sx={{ bgcolor: 'white' }}>
                    <CardContent>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                            <Typography component='h5' variant='newTitleMd' color='newNeutral'>
                                {title}
                            </Typography>
                            {closable && (
                                <Button variant='text' size='sm' sx={{ p: 1, minWidth: 0 }} onClick={onCloseHandler}>
                                    <Icon icon='mdi:close' size='md' color='newNeutral' />
                                </Button>
                            )}
                        </Stack>
                        <Divider sx={{ my: 4, borderColor: 'newNeutral.light5' }} />
                        <Box>
                            <Typography component='p' variant='newBodyMd' color='newNeutral.light2'>
                                {description}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', gap: 2 }}>
                        <Button
                            variant='contained'
                            size='sm'
                            color='newError'
                            loading={loading}
                            sx={{ px: 8 }}
                            onClick={onDelete}
                        >
                            {btnText}
                        </Button>
                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    )
}
