import axios from 'src/libs/axios/v2'
import { bulkResponseToClient, enrichResponseToClient } from 'src/utils/transforms/new-enrichment'
import type {
    AddBulkReqBody,
    AddEnrichmentReqBody,
    GetBulkFilter,
    GetBulksFilters,
    DeleteBulkFilter,
    DeleteBulkRowsFilter,
    GetEnrichesFilter,
    AddEnrichmentResponse,
    GetBulkResponse,
    GetBulksResponse,
    GetEnrichesResponse,
    Bulk,
    AddEnrichment,
    GetBulks,
    GetEnriches
} from 'src/types/enrichment'

//* Rest -----------------------------------
export const addBulk = async ({ type, name, cells }: AddBulkReqBody): Promise<AddEnrichment> => {
    const fd = new FormData()
    fd.append('type', type)
    fd.append('name', name)
    fd.append('cells', JSON.stringify(cells))
    const { data } = await axios.post<AddEnrichmentResponse>('/enrichment/bulks', fd)
    return { bulkId: data.data.bulk_id }
}
export const getBulk = async ({ id }: GetBulkFilter): Promise<Bulk> => {
    const { data } = await axios.get<GetBulkResponse>(`/enrichment/bulks/${id}`)
    return bulkResponseToClient(data.data.bulk)
}
export const getBulks = async ({ page = 1, pageSize = 10 }: GetBulksFilters): Promise<GetBulks> => {
    const start = (page - 1) * pageSize
    const limit = start + pageSize
    const { data } = await axios.get<GetBulksResponse>('/enrichment/bulks', {
        params: {
            start,
            limit
        }
    })
    return {
        items: data.data.bulks.map((bulk) => bulkResponseToClient(bulk)),
        totalCount: data.data.total_count
    }
}
export const deleteBulk = async ({ id }: DeleteBulkFilter) => {
    await axios.delete(`/enrichment/bulks/${id}`)
}
export const deleteBulkRows = async ({ bulkId, rowIds }: DeleteBulkRowsFilter) => {
    const fd = new FormData()
    fd.set('rows_id', JSON.stringify(rowIds))
    await axios.post(`/enrichment/bulks/${bulkId}/remove_rows`, fd)
}
export const addEnrichment = async ({ bulkId, inputs, service, countryCode, newBulkName }: AddEnrichmentReqBody) => {
    const fd = new FormData()
    fd.append('inputs', JSON.stringify(inputs))
    fd.append('service', service)
    if (countryCode) fd.append('country_code', countryCode)
    if (newBulkName) fd.append('new_bulk_name', newBulkName)
    await axios.post(`/enrichment/bulks/${bulkId}/actions`, fd)
}
export const getEnrichments = async ({ bulkId }: GetEnrichesFilter): Promise<GetEnriches> => {
    const { data } = await axios.get<GetEnrichesResponse>(`/enrichment/bulks/${bulkId}/actions`)
    return {
        items: data.data.actions.map((enrich) => enrichResponseToClient(enrich))
    }
}
