import MuiButton, { type ButtonProps } from '@mui/material/Button'
import CircularProgress from 'src/components/shared/CircularProgress'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Casing = 'lowercase' | 'capitalize' | 'uppercase'
export type Props = Omit<ButtonProps, 'size'> & {
    size?: Size
    bgColor?: string
    textColor?: string
    hoverBgColor?: string
    hoverTextColor?: string
    loading?: boolean
    loadingColor?: string
    casing?: Casing
}

export default function Button({
    variant = 'contained',
    size = 'md',
    color = 'newPrimary', //only main colors like newPrimary,...(newPrimary.dark or #f00 , ... are wrong)
    fullWidth = false,
    disabled = false,
    disableRipple = false, //disable ripple effect
    disableElevation = true, //control whether we should add elevated box-shadow on hover , for non-hover state we should use sx={{boxShadow}}
    bgColor, //for custom bg color , we can use 'color' prop for base colors
    textColor, //for custom text color , we can use 'color' prop for base colors
    hoverBgColor, //custom bg hover color , we can use 'color' prop for base colors , can be used for disabling hover effect
    hoverTextColor, //custom text hover color , we can use 'color' prop for base colors , can be used for disabling hover effect
    loading = false,
    loadingColor = '#fff',
    casing = 'capitalize',
    children,
    className = '',
    sx,
    ...rest
}: Props) {
    return (
        <MuiButton
            variant={variant}
            color={color}
            fullWidth={fullWidth}
            disabled={disabled}
            disableElevation={disableElevation}
            disableRipple={disableRipple}
            className={className}
            sx={{
                typography: size === 'xs' ? 'newLabelMd' : 'newLabelLg',
                boxShadow: 0,
                borderRadius: 0.8,
                textTransform:
                    casing === 'lowercase' ? 'lowercase' : casing === 'capitalize' ? 'capitalize' : 'uppercase',
                ...(!!bgColor && { bgcolor: bgColor }),
                ...(!!textColor && { color: textColor }),
                '&:hover': {
                    ...(!!hoverBgColor && { bgcolor: hoverBgColor }),
                    ...(!!hoverTextColor && { color: hoverTextColor })
                },
                '&:disabled': {
                    opacity: 0.35,
                    color: (theme) => (theme.palette[color as keyof typeof theme.palette] as any)?.contrastText,
                    bgcolor: (theme) => (theme.palette[color as keyof typeof theme.palette] as any)?.main
                },
                p: (theme) => {
                    switch (size) {
                        case 'xs':
                            return `${theme.spacing(2.125)} ${theme.spacing(3)}`
                        case 'sm':
                            return `${theme.spacing(3)} ${theme.spacing(3)}`
                        case 'md':
                            return `${theme.spacing(5)} ${theme.spacing(4)}`
                        case 'lg':
                            return `${theme.spacing(6)} ${theme.spacing(5)}`
                        case 'xl':
                            return `${theme.spacing(7)} ${theme.spacing(6)}`
                        default:
                            return `${theme.spacing(5)} ${theme.spacing(4)}`
                    }
                },
                ...sx
            }}
            {...rest}
        >
            {loading ? (
                <CircularProgress
                    variant='indeterminate'
                    size='md'
                    sx={{
                        color: loadingColor
                    }}
                />
            ) : (
                children
            )}
        </MuiButton>
    )
}

//*Example#1: Simple Usage
{
    /*
<Button variant='contained' size='md' color='newPrimary'
    disabled={false} fullWidth href='/'
    onClick={() => {}}
>
    <Icon icon='mdi:user' color='#fff' size='md' style={{ marginRight: '4px' }} />
    Text
</Button>
    */
}
//*Example#2: Disable hover colors
{
    /*
<Button bgColor='newNeutral.light5' textColor='newNeutral.main'
    hoverBgColor='newNeutral.light5' hoverTextColor='newNeutral.main'
/>
    */
}
