// ** react imports
import { useEffect, FC, PropsWithChildren, useMemo } from 'react'

// ** react router imports
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

// ** store imports
import useUser from 'src/store/user'

// ** custom components imports
import Spinner from '../spinner'

// ** models imports
import { UserModel } from 'src/shared/models'

// ** configs imports
import authConfigs from 'src/configs/auth'
import { useLocalStorage } from 'usehooks-ts'

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
    // ** hooks
    const setUser = useUser((state) => state.setUser)
    const user = useUser((state) => state.user)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [token] = useLocalStorage(authConfigs.storageTokenKeyName, null, { deserializer: (data) => data })
    const [storedUser] = useLocalStorage(
        authConfigs.storageUserKeyName,
        {},
        { deserializer: (data) => JSON.parse(data) }
    )

    const userExist = useMemo(() => !!user, [user])

    useEffect(() => {
        if (!userExist && !token) {
            if (pathname !== '/') {
                navigate(
                    {
                        pathname: '/auth/signin',
                        ...(!pathname.startsWith('/error') && {
                            search: createSearchParams({ returnUrl: pathname }).toString()
                        })
                    },
                    { replace: true }
                )

                return
            }
            navigate('/auth/signin', { replace: true })
        } else if (token) {
            const requiredKeys: Array<keyof UserModel> = [
                'email',
                'firstName',
                'lastName',
                'token',
                'sign_up_date',
                'intercom_hash',
                'api_key',
                'analytics_user_id'
            ]
            const user: Omit<UserModel, 'token'> = storedUser as UserModel

            const userDataKeys = Object.keys(user)
            const notExistingKeys = requiredKeys.filter((item) => !userDataKeys.includes(item))
            if (notExistingKeys.length > 0) {
                window.localStorage.removeItem(authConfigs.storageTokenKeyName)
                window.localStorage.removeItem(authConfigs.storageUserKeyName)

                navigate('/auth/signin', { replace: true })

                return
            }

            setUser({ token, ...user })
        }
    }, [location])

    if (!userExist) {
        return <Spinner />
    }

    return <>{children}</>
}

export default AuthGuard
