// ** react imports
import { Suspense, lazy } from 'react'

// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** custom components imports
import Spinner from 'src/@core/components/spinner'

const LoginPage = lazy(() => import('src/pages/auth/login'))
const SignupPage = lazy(() => import('src/pages/auth/signup'))
const SignupB2BPage = lazy(() => import('src/pages/auth/signup-b2b'))
const ForgetPasswordPage = lazy(() => import('src/pages/auth/forget-password'))
const LinkedInVerificationScreen = lazy(() => import('src/pages/auth/linkedin-verification'))

const authRoutes: Array<RouteObject> = [
    {
        id: 'normal-users-auth-routes-group',
        children: [
            {
                id: 'sing-up-screen',
                path: 'signup',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <SignupPage />
                    </Suspense>
                )
            },
            {
                id: 'sing-in-screen',
                path: 'signin',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <LoginPage />
                    </Suspense>
                )
            },
            {
                id: 'forget-password-screen',
                path: 'forgot-password',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ForgetPasswordPage />
                    </Suspense>
                )
            },
            {
                id: 'linkedin-verification-screen',
                path: 'linkedin_verification',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <LinkedInVerificationScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'b2b-users-auth-routes-group',
        path: 'b2b',
        children: [
            {
                id: 'b2b-sign-up-screen',
                path: 'signup',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <SignupB2BPage />
                    </Suspense>
                )
            }
        ]
    }
]

export default authRoutes
