import {
    useState,
    useRef,
    useEffect,
    forwardRef,
    type ChangeEvent,
    type FocusEvent,
    type AnimationEvent,
    type MutableRefObject,
    type ForwardedRef
} from 'react'
import MuiTextField, { type TextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from 'src/components/shared/CircularProgress'
import Icon from 'src/components/shared/Icon'

type Size = 'xs' | 'sm' | 'md'
export type Props = Omit<TextFieldProps, 'size' | 'ref' | 'inputRef'> & {
    size?: Size
    bgcolor?: string
    borderColor?: string
    prependInnerIcon?: string
    appendInnerIcon?: string
    loading?: boolean
    readOnly?: boolean
    clearable?: boolean
    clearableAlwaysVisible?: boolean
    onClearClick?: () => void
}

const Textfield = (
    {
        value,
        focused, //default value should be undefined and not false or ...
        size = 'md',
        bgcolor,
        borderColor, //for non active,error,disabled states
        color = 'newPrimary',
        readOnly = false,
        disabled = false,
        prependInnerIcon,
        appendInnerIcon,
        error = false,
        fullWidth = true,
        loading = false,
        clearable = false,
        clearableAlwaysVisible = false, //useful if we are setting uncontrolled version('register' method of react-hook-form,...) and also we validation + clearable ... we can pass it to true for fix clear icon not gets visible
        onClearClick,
        InputProps, //props that we need to set directly on <input />(not on wrapper <div />)
        InputLabelProps,
        onFocus,
        onBlur,
        onChange,
        sx,
        ...rest
    }: Props,
    ref?: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null!)
    // we need 2 different state for hold focus,labelShrink states because there are times we don't focus on input but because we have value we still need to have labelShrink:true
    const [focus, setFocus] = useState(false)
    const [labelShrink, setLabelShrink] = useState(false)
    const adornmentColor = error ? 'error' : 'newNeutral.light2' //for focus we don't change their color
    const showAdornments = {
        start: !!prependInnerIcon,
        end: !!(appendInnerIcon || clearable || loading)
    }
    const onFocusHandler = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocus(true) //we always focus on onFocus even if we don't have any value
        onFocus && onFocus(e)
    }
    const onBlurHandler = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocus(false)
        //setFocus(!!e.target.value) //if we have value then focus should still be true
        onBlur && onBlur(e) //we always call onBlur callback if we have it
    }
    const onClearClickHandler = () => {
        if (onChange) onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
        if (onClearClick) onClearClick()
        inputRef.current.focus()
    }
    useEffect(() => {
        setLabelShrink(!!(value || focus || focused))
    }, [value, focus, focused])

    return (
        <MuiTextField
            inputRef={(node: null | HTMLInputElement | HTMLTextAreaElement) => {
                //inputRef mui prop is reference of <input />,<textarea /> , while mui ref prop is reference of container <div />
                if (node) {
                    inputRef.current = node
                    if (ref) (ref as MutableRefObject<HTMLElement>).current = node
                }
            }}
            value={value}
            focused={focused}
            color={color}
            error={error}
            fullWidth={fullWidth}
            disabled={disabled}
            InputLabelProps={{
                shrink: labelShrink,
                ...InputLabelProps
            }}
            InputProps={{
                readOnly,
                disabled,
                startAdornment: showAdornments.start ? (
                    <InputAdornment position='start' sx={{ pointerEvents: 'none', height: 'auto', mr: 2 }}>
                        <Icon icon={prependInnerIcon || ''} size='md' color={adornmentColor} />
                    </InputAdornment>
                ) : null,
                endAdornment: showAdornments.end ? (
                    <InputAdornment position='end' sx={{ height: 'auto', ml: 2 }}>
                        {loading && (
                            <CircularProgress variant='indeterminate' size='md' color={color} sx={{ flexShrink: 0 }} />
                        )}
                        {!!(clearableAlwaysVisible || (clearable && value)) && (
                            <button
                                type='button'
                                onClick={onClearClickHandler}
                                style={{
                                    marginLeft: '.5rem',
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent',
                                    cursor: 'pointer'
                                }}
                            >
                                <Icon
                                    display='block'
                                    icon='mdi:close'
                                    size='md'
                                    color={adornmentColor}
                                    style={{ pointerEvents: 'none' }}
                                />
                            </button>
                        )}
                        <Icon
                            icon={appendInnerIcon || ''}
                            size='md'
                            color={adornmentColor}
                            style={{
                                marginLeft: '.5rem'
                            }}
                        />
                    </InputAdornment>
                ) : null,
                //* For handle label collision with autofill value if we have autoComplete
                onAnimationStart: (e: AnimationEvent<HTMLElement>) => {
                    e.animationName === 'mui-auto-fill' && setLabelShrink(true)
                    // e.animationName === 'mui-auto-fill-cancel' && setLabelShrink(false)
                },
                // onAnimationEnd: (e: AnimationEvent<HTMLElement>) => {
                //     e.animationName === 'mui-auto-fill-cancel' && setLabelShrink(false)
                // },
                // className: '',
                // sx:{},
                ...InputProps
            }}
            onChange={onChange}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            sx={{
                opacity: disabled ? 0.5 : 1,
                '& .MuiInputBase-root': {
                    height: size === 'xs' ? '2.625rem' : size === 'sm' ? '3rem' : '4rem',
                    borderRadius: 0.8,
                    bgcolor
                },
                '& label': {
                    transform: () => {
                        if (size === 'xs' && !labelShrink && !showAdornments.start)
                            return 'translate(1rem,.7rem) scale(1)'
                        else if (size === 'xs' && !labelShrink && showAdornments.start)
                            return 'translate(2.5rem,.7rem) scale(1)'
                        else if (size === 'xs' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                        else if (size === 'sm' && !labelShrink && !showAdornments.start)
                            return 'translate(1rem,.8rem) scale(1)'
                        else if (size === 'sm' && !labelShrink && showAdornments.start)
                            return 'translate(2.5rem,.8rem) scale(1)'
                        else if (size === 'sm' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                        else if (size === 'md' && !labelShrink && !showAdornments.start)
                            return 'translate(1rem,1.3rem) scale(1)'
                        else if (size === 'md' && !labelShrink && showAdornments.start)
                            return 'translate(2.5rem,1.3rem) scale(1)'
                        else if (size === 'md' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                    }
                },
                '& fieldset': {
                    borderColor: (theme) => {
                        const split = borderColor?.split('.')
                        const colorName = split?.[0] || 'newNeutral'
                        const colorVariant = split?.[1] || 'main'
                        //@ts-expect-error 'find color'
                        const color: string = theme.palette[colorName][colorVariant] || borderColor || 'transparent'
                        return !focus && !error && !disabled ? `${color} !important` : undefined
                    }
                },
                ...sx
            }}
            {...rest}
        />
    )
}

export default forwardRef(Textfield)

//? No need to manually set id and mui will generate auto id for us , if we set 'id' prop then 'for' attribute of <labe> will be set automatically too
//? We have props like: type,inputMode,placeholder,label,name,id,focused,fullWidth,required,variant,value,...
//? We have events like: onFocus,onBlur,onInput,onChange,onKeyDown,onKeyUp
//? For convert to <textarea /> we have props like  multiline,minRows,rows,maxRows
//? In modern browsers autoComplete="off" on <form>,form fields will not work ... instead we should use autoComplete="one-time-code" on each form field
//? For outline field that its label will not go on top on border --> change 'transforms' value of label when we shrink it + totally hide <legend>
//* Example#1: simple usage
{
    //const [name,setName] = useState('')
    /* <TextField variant='outlined' size='md' color='newPrimary'
    placeholder='Name' label='name'
    value={name} onChange={(e) => setName(e.target.value)}
    prependInnerIcon='mdi:user' appendInnerIcon='mdi:user'
    loading clearable onClearClick={() => setName('')}
    error={error} helperText='helper'
    sx={{ mt: 5, width: 300 }}
/> */
}
//* Example#2: react-hook-form validation(controlled) ... we should only use controlled version
{
    // import {Controller,useForm} from 'react-hook-form'
    // const {control,formState:{errors}} = useForm()
    // <Controller name='name' control={control} rules={{required: 'Name is required'}}
    //     render={({ field }) => (
    //         <Textfield {...field} variant='outlined' size='md' color='newPrimary'
    //             label='Full Name' error={!!errors.name} helperText={errors.name?.message}
    //             prependInnerIcon='solar:user-outline' sx={{ mt: 5 }}
    //         />
    // )}
    // ></Controller>
}
