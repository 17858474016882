import { Fragment } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { useQuery, useMutation } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Form from 'src/components/shared/Form'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import Link from 'src/components/shared/Link'
import Textfield from 'src/components/shared/Textfield'
import CountrySelect from 'src/components/wrappers/CountrySelect'
import Tooltip from 'src/components/shared/Tooltip'
import EnrichInput from 'src/components/new-enrichment/enrich/Input'
import { getBulk, addEnrichment } from 'src/services/new-enrichment'
import type { Service, EnrichForm } from 'src/types/enrichment'

type Props = BoxProps &
    Service & {
        bulkId: number
        onSubmit?: () => void
    }

export default function EnrichServiceIO({
    bulkId,
    type,
    code,
    title,
    credits = 0,
    description,
    link,
    inputs = [],
    outputs = [],
    onSubmit,
    ...rest
}: Props) {
    const theme = useTheme()
    const { control, handleSubmit, reset } = useForm<EnrichForm>({
        mode: 'onSubmit',
        defaultValues: {
            inputs: inputs.map((inp) => ({ ...inp, value: null }))
        }
    })
    const { fields: inputFields } = useFieldArray({
        control,
        name: 'inputs'
    })
    const { isFetching: bulkLoading, data: bulk } = useQuery({
        initialData: null,
        queryKey: ['get-bulk', bulkId],
        queryFn: async () => {
            const bulk = await getBulk({ id: bulkId })
            return bulk
        }
    })
    const { isPending: enrichLoading, mutateAsync: addEnrich } = useMutation<unknown, Error, EnrichForm>({
        mutationFn: async ({ inputs = [], countryCode, newBulkName }) => {
            await addEnrichment({
                bulkId,
                service: code,
                inputs: inputs.map((inp) => inp.value?.value).filter((inp) => inp) as string[],
                countryCode: countryCode?.[0]?.value,
                newBulkName
            })
        }
    })
    const onEnrichSubmit = async ({ inputs = [], countryCode, newBulkName }: EnrichForm) => {
        await addEnrich({ inputs, countryCode, newBulkName })
        reset()
        if (onSubmit) onSubmit()
    }
    const inputOptions = bulk?.cellsDataNormalize.length
        ? bulk.cellsDataNormalize[0].map((header) => ({ label: `${header}`, value: `${header}` }))
        : []
    const inputTitles = inputs.map((input) => input.title.split(' or '))

    return (
        <Box height={1} {...rest}>
            <Form onSubmit={handleSubmit(onEnrichSubmit)} className='h-full'>
                <Stack justifyContent='space-between' gap={5} height={1}>
                    <Box>
                        <Box>
                            <Box>
                                <Typography component='h2' variant='newTitleSm' color='newNeutral'>
                                    <Icon icon='ic:twotone-info' size='lg' color='newWarning' className='mr-2 -mb-2' />
                                    About This Service
                                </Typography>
                                {!!description && (
                                    <Typography component='p' variant='newBodySm' color='newNeutral.light2' mt={2}>
                                        {description}
                                    </Typography>
                                )}
                            </Box>
                            <Box mt={6}>
                                <Typography component='p' variant='newBodyMd' color='newNeutral'>
                                    Valid Input
                                </Typography>
                                <Stack gap={2} mt={3}>
                                    {inputTitles.map((titles, i) => (
                                        <Stack key={i} direction='row' gap={2} alignItems='center' flexWrap='wrap'>
                                            {titles.map((title, j) => (
                                                <Fragment key={title}>
                                                    <Chip
                                                        variant='filled'
                                                        size='small'
                                                        label={
                                                            <Typography
                                                                component='span'
                                                                variant='newLabelSm'
                                                                color='newSuccess.dark4'
                                                            >
                                                                {title}
                                                            </Typography>
                                                        }
                                                        sx={{ bgcolor: 'newSuccess.light4', px: 2 }}
                                                    />
                                                    {j < titles.length - 1 && (
                                                        <Typography
                                                            component='span'
                                                            variant='newBodySm'
                                                            color='newNeutral.light3'
                                                        >
                                                            Or
                                                        </Typography>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Box>
                            <Box mt={6}>
                                <Typography component='p' variant='newBodyMd' color='newNeutral'>
                                    Output
                                </Typography>
                                <Typography
                                    component='p'
                                    variant='newLabelSm'
                                    color='newNeutral.light2'
                                    lineHeight={2.5}
                                    mt={3}
                                >
                                    {outputs.join(' , ')}
                                </Typography>
                            </Box>
                            {!!link && (
                                <Link
                                    href={link}
                                    target='_blank'
                                    sx={{
                                        mt: 5,
                                        display: 'block',
                                        width: 1,
                                        borderRadius: 1,
                                        py: 3,
                                        bgcolor: 'newSurface.light',
                                        color: 'newNeutral.light2',
                                        border: 1,
                                        borderColor: 'newSurface.main'
                                    }}
                                >
                                    Learn More About This Service
                                </Link>
                            )}
                        </Box>
                        <Divider sx={{ my: 6, borderColor: 'newNeutral.light5' }} />
                        <Box>
                            <Stack direction='row' alignItems='center' gap={2}>
                                <Typography component='h3' variant='newTitleSm' color='newNeutral'>
                                    Column Mapping
                                </Typography>
                                <Tooltip title='To put the right information in each column, just click on “Select” and pick the matching field. For example, if your spreadsheet has a column named “Company Websites”, choose as “Company Domain”.'>
                                    <Icon
                                        icon='mdi:information'
                                        size='md'
                                        color='newNeutral'
                                        style={{ display: 'flex' }}
                                    />
                                </Tooltip>
                            </Stack>
                            <Box mt={6} px={3} py={1} bgcolor='newSurface.light' borderRadius={2}>
                                <List>
                                    {inputFields.map((input, i) => (
                                        <ListItem key={input.id} sx={{ px: 0 }}>
                                            <EnrichInput
                                                {...input}
                                                control={control}
                                                index={i}
                                                loading={bulkLoading}
                                                options={inputOptions}
                                                width={1}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                        {code === 'CUF' && (
                            <Controller
                                control={control}
                                name='countryCode'
                                rules={{ required: 'Country is required' }}
                                render={({ field, fieldState: { error } }) => (
                                    <CountrySelect
                                        size='sm'
                                        value={field.value || []}
                                        onChange={(_, newVal) => field.onChange(newVal)}
                                        label='Country'
                                        color='newEnrichment'
                                        error={!!error}
                                        helperText={error?.message}
                                        borderColor='newNeutral.light4'
                                        sx={{ mt: 5 }}
                                    />
                                )}
                            />
                        )}
                        {code === 'FCL' && (
                            <Controller
                                control={control}
                                name='newBulkName'
                                rules={{ required: 'Name is required' }}
                                render={({ field, fieldState: { error } }) => (
                                    <Textfield
                                        size='sm'
                                        {...field}
                                        color='newEnrichment'
                                        label='New Bulk Name'
                                        error={!!error}
                                        helperText={error?.message}
                                        borderColor='newNeutral.light4'
                                        sx={{ mt: 5 }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                    <Box p={5} bgcolor='white' borderTop={1} borderColor='newNeutral.light5'>
                        <Stack direction='row' justifyContent='center' alignItems='center' gap={2}>
                            <img src='/images/new-theme/enrichment/coins.png' alt='credits' style={{ width: '30px' }} />
                            <Typography component='span' variant='newLabelMd' color='newNeutral'>
                                {credits} Credits Usage
                            </Typography>
                        </Stack>
                        <Button
                            type='submit'
                            loading={enrichLoading}
                            variant='contained'
                            color='newEnrichment'
                            size='sm'
                            fullWidth
                            sx={{ mt: 5 }}
                        >
                            Run Action
                        </Button>
                    </Box>
                </Stack>
            </Form>
        </Box>
    )
}
