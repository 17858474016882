// ** react imports
import { ReactNode } from 'react'

// ** mui imports
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions'

export interface ModalProps extends DialogProps {
    modalTitle?: string | ReactNode
    modalTitleProps?: DialogTitleProps
    modalContentProps?: DialogContentProps
    actions?: ReactNode
    modalActionsProps?: DialogActionsProps
}

const Modal = ({
    modalTitle,
    modalTitleProps,
    modalContentProps,
    modalActionsProps,
    actions,
    ...props
}: ModalProps) => {
    return (
        <Dialog {...props}>
            {!!modalTitle ? <DialogTitle {...modalTitleProps}>{modalTitle}</DialogTitle> : null}

            <DialogContent
                {...modalContentProps}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 3,
                    mb: 5,
                    py: '10px!important',
                    ...modalContentProps?.sx
                }}
            >
                {props.children}
            </DialogContent>

            {actions ? <DialogActions {...modalActionsProps}>{actions}</DialogActions> : null}
        </Dialog>
    )
}

export default Modal
