import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import Stack, { type StackProps } from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import useUser from 'src/store/user'
import { connect, disconnect, onGetCredits } from 'src/services/socket/credits'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import { useSettings } from 'src/@core/hooks/useSettings'

type Props = StackProps

type TabValue = 'overview' | 'table'
type Tab = { pathname: string; text: string; value: TabValue; icon: string }

const intl = Intl.NumberFormat('en-US')

export default function Nav({ ...rest }: Props) {
    const { pathname } = useLocation()
    const params = useParams()
    const bulkId = params.id
    const { settings } = useSettings()
    const [tab, setTab] = useState<TabValue>('table')
    const tabs: Tab[] = [
        {
            value: 'overview',
            text: 'Overview',
            icon: 'hugeicons:workflow-circle-04',
            pathname: `/enrichment/bulks/${bulkId}/overview`
        },
        {
            value: 'table',
            text: 'Table',
            icon: 'material-symbols:table-outline-sharp',
            pathname: `/enrichment/bulks/${bulkId}`
        }
    ]
    const credits = useUser((user) => user.user?.credits || 0)
    const updateCredits = useUser((user) => user.updateCredits)
    useEffect(() => {
        setTab(pathname.includes('overview') ? 'overview' : 'table')
    }, [pathname])
    useEffect(() => {
        connect(() => {
            onGetCredits((newCredits) => {
                updateCredits(newCredits)
            })
        })
        return () => {
            disconnect()
        }
    }, [])

    return (
        <Stack
            component='nav'
            px={4}
            py={0}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
            flexWrap='wrap'
            bgcolor='newSurface.light3'
            borderBottom={1}
            borderColor='newNeutral.light5'
            {...rest}
        >
            <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2} order={{ xs: 2, sm: 1 }}>
                <Button variant='text' href='/enrichment/bulks' sx={{ p: 1, minWidth: 0 }}>
                    <Icon icon='solar:arrow-left-linear' size='md' color='newNeutral' />
                </Button>
                <Divider
                    orientation='vertical'
                    sx={{ height: 20, borderColor: 'newNeutral.light5', ml: { xs: 1, md: 2 } }}
                />
                <Tabs
                    value={tab}
                    onChange={(_, newTab) => {
                        setTab(newTab)
                    }}
                    sx={{
                        minHeight: 'auto',
                        '& .MuiTab-root': {
                            minHeight: 'auto',
                            flexDirection: 'row',
                            py: 4
                        },
                        '& .MuiTabs-indicator': {
                            bgcolor: 'newNeutral.main'
                        }
                    }}
                >
                    {tabs.map((t) => {
                        const isActive = tab === t.value
                        return (
                            <Tab
                                key={t.value}
                                value={t.value}
                                href={t.pathname}
                                icon={
                                    <Icon
                                        icon={t.icon}
                                        size={18}
                                        color={isActive ? 'newNeutral' : 'newNeutral.light2'}
                                        className='mr-2'
                                    />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        variant='newLabelMd'
                                        color={isActive ? 'newNeutral.main' : 'newNeutral.light2'}
                                    >
                                        {t.text}
                                    </Typography>
                                }
                            />
                        )
                    })}
                </Tabs>
            </Stack>
            <Stack direction='row' alignItems='center' gap={2} order={{ xs: 1, sm: 2 }}>
                <Stack direction='row' alignItems='center' gap={2}>
                    <img src='/images/new-theme/enrichment/coins-2.png' alt='credits' style={{ width: '25px' }} />
                    <Typography variant='newLabelSm' color='newNeutral'>
                        {intl.format(credits)}
                    </Typography>
                </Stack>
                <Button variant='text' size='xs' sx={{ color: 'newSuccess.dark4', typography: 'newLabelSm' }}>
                    Upgrade your plan
                </Button>
                <UserDropdown settings={settings} />
            </Stack>
        </Stack>
    )
}
