// ** react imports
import { PropsWithChildren, useContext } from 'react'

// ** component imports
import { AbilityContext } from 'src/layouts/components/acl/Can'

// ** types
import { NavLink } from 'src/@core/layouts/types'

interface Props {
    navLink?: NavLink
}

const CanViewNavLink = (props: PropsWithChildren<Props>) => {
    // ** props
    const { children, navLink } = props

    return children
}

export default CanViewNavLink
