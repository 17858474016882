import Stack, { type StackProps } from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Icon, { type Size as IconSize } from 'src/components/shared/Icon'

type Props = StackProps & {
    title?: string
    description?: string
    icon?: string
    iconColor?: string
    iconSize?: IconSize
}

const NoResult = ({
    title = 'No Results ...',
    description,
    icon = 'solar:clipboard-list-bold-duotone',
    iconColor = 'newNeutral.light4',
    iconSize = 100,
    ...rest
}: Props) => {
    return (
        <Stack justifyContent='center' alignItems='center' gap={5} {...rest}>
            <Icon icon={icon} color={iconColor} size={iconSize} />
            <Typography component='p' variant='newTitleMd' color='newNeutral'>
                {title}
            </Typography>
            {!!description && (
                <Typography component='p' variant='newBodyMd' color='newNeutral.light2'>
                    {description}
                </Typography>
            )}
        </Stack>
    )
}

export default NoResult
