import socketManager from './manager'
import authConfigs from 'src/configs/auth'
import type { SocketResponse } from 'src/types/Credit'

export const socket = socketManager.socket('/credits', {
    auth: (callback) => {
        const token = localStorage.getItem(authConfigs.storageTokenKeyName)
        callback({ token })
    }
})
export const onGetCredits = (cb?: (val: number) => void) => {
    socket.on('update', (data: SocketResponse) => {
        if (cb) cb(data?.credits?.credits || 0)
    })
}
export const connect = (cb?: () => void) => {
    socket.connect()
    socket.on('connect', () => {
        if (cb) cb()
    })
}
export const disconnect = (cb?: () => void) => {
    socket.disconnect()
    socket.on('disconnect', () => {
        if (cb) cb()
    })
}
