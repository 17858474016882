import type { Response } from 'src/types/Server'

//* Common -----------------------
export type Type = 'company' | 'contact'
export enum Status {
    PENDING = 0,
    PROCESSED = 1,
    NEW = 2
}
export type FileType = 'xls' | 'xlsx' | 'csv'
export type Option = {
    label: string
    value: string
}
//* Bulks -----------------------
export type CellData = {
    _id: string
    [key: string]: unknown
}
export type UploadBulk = {
    type: null | Type
    name: string
    file: null | File
}
export type Bulk = {
    id: number
    createdAt: string
    updatedAt: string
    type: Type
    status: Status
    name: string
    rowCount: number
    cellsData: CellData[]
    cellsDataNormalize: unknown[][]
}
export type BulkResponse = {
    _id: string
    user_id: number
    bulk_id: number
    type: Type
    name: string
    status: Status
    rows_count: number
    creation_date: Date
    last_update: Date
    cells_data?: CellData[]
}
export type AddBulkReqBody = {
    type: Type
    name: string
    cells: unknown[][]
}
export type GetBulkFilter = {
    id?: number | string
}
export type GetBulkResponse = Response & { data: { bulk: BulkResponse } }
export type GetBulksFilters = {
    page?: number
    pageSize?: number
}
export type GetBulks = {
    items: Bulk[]
    totalCount: number
}
export type GetBulksResponse = Response & {
    data: { bulks: BulkResponse[]; total_count: number }
}
export type DeleteBulkFilter = {
    id: number | string
}
export type DeleteBulkRowsFilter = {
    bulkId: number
    rowIds: string[]
}
//* Enrichments -----------------------
export type ServiceCode =
    | 'CUF'
    | 'ENC'
    | 'LCI'
    | 'FCL'
    | 'ELF'
    | 'CAR'
    | 'CND'
    | 'FEA'
    | 'FPA'
    | 'FCC'
    | 'FTS'
    | 'EPP'
    | 'EPE'
    | 'FWE'
    | 'TEP'
export type Input = {
    field: string
    title: string
    required?: boolean
    value?: null | Option
    options?: Option[]
}
export type Service = {
    type: Type
    title: string
    code: ServiceCode
    credits: number
    description?: string
    icon: string
    color: string
    link?: string
    inputs: Input[]
    outputs: string[]
}
export type EnrichForm = {
    inputs: Input[]
    countryCode?: Option[]
    newBulkName?: string
}
export type Enrich = {
    bulkId: number
    serviceCode: ServiceCode
    passed: number
    rowCounts: number
    startDate: null | string
    endDate: null | string
}
export type EnrichResponse = {
    bulk_id: number
    service: ServiceCode
    passed: number
    rows_count: number
    start_time: null | Date
    end_time: null | Date
}
export type AddEnrichment = Pick<Enrich, 'bulkId'>
export type AddEnrichmentReqBody = {
    bulkId: number
    inputs: string[]
    service: ServiceCode
    countryCode?: string
    newBulkName?: string
}
export type AddEnrichmentResponse = Response & {
    data: Pick<EnrichResponse, 'bulk_id'>
}
export type GetEnrichesFilter = {
    bulkId: number
}
export type GetEnriches = {
    items: Enrich[]
}
export type GetEnrichesResponse = Response & {
    data: {
        actions: EnrichResponse[]
    }
}
//* Sockets -----------------------
export type SocketBulkActions = 'get_bulk' | 'bulk_info_update'
export type SocketBulk = Bulk & { isExist: boolean; processingColumns: string[] }
export type SocketBulkResponse = {
    exists: boolean
    columns?: string[]
    bulk: BulkResponse
}
export type SocketGetBulkFilter = { id: number }
