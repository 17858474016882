import {
    useState,
    useEffect,
    useRef,
    forwardRef,
    type ChangeEvent,
    type FocusEvent,
    type SyntheticEvent,
    type ReactElement,
    type MutableRefObject,
    type ForwardedRef
} from 'react'
import MuiAutoComplete, { type AutocompleteProps } from '@mui/material/Autocomplete'
import MuiTextField, { type TextFieldProps } from '@mui/material/TextField'
//? We don't use custom Textfield wrapper component because of we face typing conflicts and instead directly import it from mui
import MuiInputAdornment from '@mui/material/InputAdornment'
import Icon from 'src/components/shared/Icon'
import CircularProgress from 'src/components/shared/CircularProgress'

type Size = 'xs' | 'sm' | 'md'
export type Option = {
    label: string
    value: number | string
    [key: string]: unknown
}
export type Props<
    Value,
    Multiple extends undefined | boolean = false,
    DisableClearable extends undefined | boolean = false,
    FreeSolo extends undefined | boolean = false
    // ChipComponent extends ElementType = 'div'
    //? We don't use 'ChipComponent' generic inside AutocompleteProps because it will slow down whole component(s)
> = Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, 'size' | 'onChange' | 'renderInput'> &
    Pick<
        TextFieldProps,
        'variant' | 'color' | 'placeholder' | 'label' | 'autoFocus' | 'error' | 'helperText' | 'onKeyDown' | 'onKeyUp'
    > & {
        name?: string
        size?: Size
        bgcolor?: string
        borderColor?: string
        prependInnerIcon?: string
        appendInnerIcon?: string
        loading?: boolean
        clearable?: boolean
        onClearClick?: () => void
        onChange?: (
            e: SyntheticEvent,
            newVal: FreeSolo extends true
                ? Multiple extends true
                    ? (string | Value)[]
                    : null | string | Value
                : Multiple extends true
                  ? Value[]
                  : null | Value
        ) => void
    }

const AutoComplete = <
    Value,
    Multiple extends undefined | boolean = false,
    DisableClearable extends undefined | boolean = false,
    FreeSolo extends undefined | boolean = false
>(
    {
        //our own custom props
        name,
        size = 'md',
        prependInnerIcon,
        appendInnerIcon,
        clearable = false,
        onClearClick,
        //props that we get from TextField and apply on TextField
        variant = 'outlined',
        color = 'newPrimary',
        bgcolor,
        borderColor, //for non active,error,disabled states
        placeholder,
        label,
        autoFocus = false,
        error,
        helperText,
        onKeyDown,
        onKeyUp,
        //props that we get from AutoComplete and apply on AutoComplete
        value,
        onChange,
        fullWidth = true,
        multiple,
        loading = false,
        disabled = false,
        onFocus,
        onBlur,
        sx,
        // Other important props: options,readOnly,disabled,blurOnSelect,autoSelect,freeSolo(create combo-box),openOnFocus,clearOnBlur,selectOnFocus,filterSelectedOptions(useful for multiple:true),limitTags,getOptionLabel(label key of options),renderTags(jsx of value ... will only work for multiple:true),renderOption(jsx of option),filterOptions(logic of options filtering)
        //getOptionKey={opt=>opt.value} --> set value key of each option ... getOptionLabel={opt => opt.label} --> set label key of each option
        //isOptionEqualToValue={(option, value) => option.value === value.value} --> we must set it for multiple:true we can select duplicated values + filterSelectedOptions prop will not work
        //slots,slotProps --> for customize different part of AutoComplete e.g Paper,Chip,... --> slotProps={{paper: {sx: {'& .MuiAutocomplete-option': {py: 3}}}}}
        ...rest
    }: Props<Value, Multiple, DisableClearable, FreeSolo>,
    ref?: ForwardedRef<HTMLInputElement>
) => {
    const inputRef = useRef<HTMLInputElement>(null!)
    const [isFocus, setIsFocus] = useState<boolean>(autoFocus)
    const [labelShrink, setLabelShrink] = useState(false)
    const accentColor = error ? 'newError' : isFocus ? color : 'newNeutral.light2'
    const hasValue = !!((!multiple && value) || (multiple && (value as unknown[])?.length))
    const showClearable = clearable && hasValue
    const showAdornments = {
        start: !!prependInnerIcon,
        end: !!(loading || showClearable || appendInnerIcon)
    }
    const onRef = (node: null | HTMLInputElement) => {
        if (node) {
            inputRef.current = node
            if (ref) (ref as MutableRefObject<HTMLInputElement>).current = node
        }
    }
    const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocus(true)
        if (onFocus) onFocus(e)
    }
    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocus(false)
        if (onBlur) onBlur(e)
    }
    const clearHandler = () => {
        if (onChange) {
            onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>, (!multiple ? null : []) as any)
        }
        if (onClearClick) onClearClick() //on parent if we have multiple={false} use null on value else use []
    }
    useEffect(() => {
        setLabelShrink(hasValue || isFocus)
    }, [hasValue, isFocus])

    return (
        <>
            <MuiAutoComplete
                fullWidth={fullWidth}
                value={value}
                //@ts-expect-error 'manually type onChange'
                onChange={onChange}
                multiple={multiple}
                size={size === 'xs' || size === 'sm' ? 'small' : 'medium'}
                loading={loading}
                clearIcon={null}
                disabled={disabled}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                renderInput={(params) => (
                    <MuiTextField
                        {...params}
                        inputRef={onRef}
                        name={name}
                        variant={variant}
                        color={color}
                        placeholder={placeholder}
                        label={label}
                        error={error}
                        helperText={helperText}
                        autoFocus={autoFocus}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {showAdornments.start && (
                                        <MuiInputAdornment position='start' sx={{ pointerEvents: 'none' }}>
                                            <Icon icon={prependInnerIcon!} size='md' color={accentColor} />
                                        </MuiInputAdornment>
                                    )}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                            endAdornment: (
                                <>
                                    {showAdornments.end && (
                                        <MuiInputAdornment
                                            position='end'
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                position: 'absolute',
                                                right: 35
                                            }}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    variant='indeterminate'
                                                    color={color}
                                                    size={25}
                                                    thickness={2}
                                                />
                                            ) : null}
                                            {showClearable ? (
                                                <button
                                                    type='button'
                                                    onClick={clearHandler}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        background: 'transparent',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <Icon
                                                        icon='mdi:close'
                                                        size='md'
                                                        color={accentColor}
                                                        style={{ pointerEvents: 'none' }}
                                                    />
                                                </button>
                                            ) : null}
                                            {appendInnerIcon ? (
                                                <Icon icon={appendInnerIcon} size='md' color={accentColor} />
                                            ) : null}
                                        </MuiInputAdornment>
                                    )}
                                    {params.InputProps.endAdornment}
                                    {/* params.InputProps.endAdornment is responsive for showing select arrow so if we want custom arrow we should comment it and use new jsx */}
                                </>
                            )
                        }}
                    />
                )}
                sx={{
                    opacity: disabled ? 0.5 : 1,
                    '& .MuiInputBase-root': {
                        height: size === 'xs' ? '2.625rem' : size === 'sm' ? '3rem' : '4rem',
                        borderRadius: 0.8,
                        bgcolor
                    },
                    '& label': {
                        pointerEvents: 'none',
                        transform: () => {
                            if (size === 'xs' && !labelShrink && !showAdornments.start)
                                return 'translate(1rem,.7rem) scale(1)'
                            else if (size === 'xs' && !labelShrink && showAdornments.start)
                                return 'translate(2.5rem,.7rem) scale(1)'
                            else if (size === 'xs' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                            else if (size === 'sm' && !labelShrink && !showAdornments.start)
                                return 'translate(1rem,.8rem) scale(1)'
                            else if (size === 'sm' && !labelShrink && showAdornments.start)
                                return 'translate(2.5rem,.8rem) scale(1)'
                            else if (size === 'sm' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                            else if (size === 'md' && !labelShrink && !showAdornments.start)
                                return 'translate(1rem,1.3rem) scale(1)'
                            else if (size === 'md' && !labelShrink && showAdornments.start)
                                return 'translate(2.5rem,1.3rem) scale(1)'
                            else if (size === 'md' && labelShrink) return 'translate(.9rem,-.5rem) scale(.75)'
                        }
                    },
                    '& fieldset': {
                        borderColor: (theme) => {
                            const split = borderColor?.split('.')
                            const colorName = split?.[0] || 'newNeutral'
                            const colorVariant = split?.[1] || 'main'
                            //@ts-expect-error 'find color'
                            const color: string = theme.palette[colorName][colorVariant] || borderColor || 'transparent'
                            return !isFocus && !error && !disabled ? `${color} !important` : undefined //for focus,error use default behavior else manually set border color,
                        },

                        '& legend': {
                            maxWidth: !labelShrink ? '0' : undefined
                        }
                    },
                    ...sx
                }}
                {...rest}
            />
        </>
    )
}

export default forwardRef(AutoComplete) as <
    Value,
    Multiple extends undefined | boolean = false,
    DisableClearable extends undefined | boolean = false,
    FreeSolo extends undefined | boolean = false
>(
    props: Props<Value, Multiple, DisableClearable, FreeSolo>,
    ref?: ForwardedRef<HTMLInputElement>
) => ReactElement
//* here we use forward ref but could totally omit forward ref and for react-hook-form just manually set value,onChange:
// Forward ref version: <Controller control={control} render={({ field}) => (<CountrySelect {...field} onChange={(_, v) => field.onChange(v)} />) /> // destruct whole 'field' so we get name,value,ref,onBlur,... and manually set onChange
// Non Forward ref version: <Controller control={control} render={({ field}) => (<CountrySelect value={field.value} onChange={(_, v) => field.onChange(v)} />) /> //only manually set value,onChange and not set ref,name,... at all

//? Examples:
//* #1:Single Select with important props and controlled version of menu,textfield:
// const [menu, setMenu] = useState(false)
// const [search, setSearch] = useState('')
// const [skill, setSkill] = useState<null | Opt>(null)
// const [isError, setIsError] = useState(false)
// const skillOptions: Opt[] = [
//     {value: 'html',label: 'HTML',desc: 'desc-1'},
//     {value: 'css',label: 'CSS',desc: 'desc-2'},
// ]
// <button onClick={() => setIsError((old) => !old)}>toggle error</button>
// <AutoComplete // multiple={false}
//     variant='outlined' size='sm' label='my label' color='newSuccess'
//     value={skill} onChange={(e, value, reason) => setSkill(value)}
//     onClearClick={() => setSkill(null)}
//     inputValue={search} onInputChange={(e, newValue) => setSearch(newValue)}
//     open={menu} onOpen={() => setMenu(true)} onClose={() => setMenu(false)}
//     options={skillOptions}
//     loading clearable prependInnerIcon='mdi:user'
//     error={isError} helperText='text'
//     sx={{ width: 500, mt: 10 }}
// />
//* #2:Multiple Select with custom jsx:
// const [skills, setSkills] = useState<Opt[]>([])
// const skillOptions: Opt[] = [
//     {value: 'html',label: 'HTML',desc: 'desc-1'},
//     {value: 'css',label: 'CSS',desc: 'desc-2'},
// ]
// <AutoComplete
//     multiple filterSelectedOptions
//     isOptionEqualToValue={(option, value) => option.value === value.value} //if we don't set this prop we can select duplicate options so for multiple:true we must set it
//     value={skills}
//     onChange={(e, value, reason) => setSkills(value)}
//     options={skillOptions}
//     placeholder='my label' clearable onClearClick={() => setSkills([])}
//     renderOption={(props, option,state,ownerState) => {
//        const { key, ...rest } = props
//        return <Box key={key} {...rest} component='li'>{option.label}</Box>
//     }}
//     renderTags={(value, getTagProps,ownerState) =>
//        value.map((val, index) => {
//           const { key, ...rest } = getTagProps({ index })
//           return <Chip key={key} {...rest} label={val.label} />
//        })
//     }
//     slotProps={{paper: {sx: {'& .MuiAutocomplete-option': {py: 3}}}}}
//     sx={{'& .MuiInputBase-root': {height: 'auto'},}} //if we see height of multiple:true not increase by selecting options we can manually do it
// />
//* #3: Validation with react-hook-form:
// type Option = {label: string,value: string}
// type Fields = {skill: null | Option}
// const options: Option[] = [{value: 'HTML',label: 'html'},{value: 'CSS',label: 'css'}]
// const {control} = useForm<Fields>({mode: 'onSubmit',defaultValues: {skill: null}})
// <Controller
//     control={control} name='skill' rules={{ required: 'required skill' }}
//     render={({ field, fieldState: { error } }) => (
//         <AutoComplete
//             {...field}
//             onChange={(_, newVal) => field.onChange(newVal || null)}
//             options={options} clearable error={!!error} helperText={error?.message}
//         />
//     )}
// />
