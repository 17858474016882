import { Fragment } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Icon from 'src/components/shared/Icon'
import type { Service } from 'src/types/enrichment'

type Props = Omit<BoxProps, 'onChange'> & {
    title: string
    icon: string
    iconColor?: string
    services: Service[]
    onChange?: (newService: Service) => void
}

export default function EnrichServicesList({
    title,
    icon,
    iconColor = 'newEnrichment',
    services = [],
    onChange,
    ...rest
}: Props) {
    return (
        <Box {...rest}>
            {/* <Stack direction='row' alignItems='center' spacing={2} mb={2}>
                <Icon icon={icon} color={iconColor} size='lg' />
                <Typography variant='newLabelMd' component='p' color='newNeutral.light2'>
                    {title}
                </Typography>
            </Stack> */}
            <Box>
                <List>
                    {services.map((service, i) => (
                        <Fragment key={service.code}>
                            <ListItem
                                sx={{
                                    p: 0,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    transition: 'background-color 2s linear',
                                    '& :hover': { bgcolor: 'newSurface.light' }
                                }}
                            >
                                <Stack
                                    width={1}
                                    px={2}
                                    py={4}
                                    display='flex'
                                    direction='row'
                                    alignItems='center'
                                    gap={2}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (onChange) onChange(service)
                                    }}
                                >
                                    <Stack
                                        width={36}
                                        height={36}
                                        bgcolor='white'
                                        border={1}
                                        borderColor='newNeutral.light5'
                                        borderRadius='50%'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Icon icon={service.icon} color={service.color} size='md' />
                                    </Stack>
                                    <Typography color='newNeutral' variant='newLabelLg' component='p'>
                                        {service.title}
                                    </Typography>
                                </Stack>
                            </ListItem>
                            {/* {i < services.length - 1 && (
                                <ListItem sx={{ px: 0, py: 2.5 }}>
                                    <Divider
                                        variant='fullWidth'
                                        orientation='horizontal'
                                        sx={{ width: 1, my: 0, borderColor: 'newSurface.main' }}
                                    />
                                </ListItem>
                            )} */}
                        </Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    )
}
