// ** react imports
import { PropsWithChildren, createContext, useState } from 'react'

// ** shared imports
import { type NewCampaignModel, CampaignStatus } from 'src/shared/types/services'

// ** third party imports
import moment from 'moment'

type CampaignContextModel = {
    campaign: NewCampaignModel
    saveCampaign: (data: Partial<NewCampaignModel>) => void
}

const initialCampaignData: NewCampaignModel = {
    name: '',
    status: CampaignStatus.ACTIVE,
    steps: [
        {
            order: 1,
            subject: '',
            content: null,
            offset: 1
        }
    ],
    schedules: [
        {
            order: 1,
            name: 'New Schedule',
            days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
            start_time: '09:00:00',
            end_time: '18:00:00',
            timezone: 'Eastern Time (US & Canada) (UTC-04:00)'
        }
    ],
    start_date: moment().toDate(),
    senders: [],
    stopOnReply: true,
    stopOnAutoreply: true,
    openTracking: false,
    textOnly: false,
    linkTracking: true,
    maxNewLeads: 20,
    dailyLimit: 20,
    cc: [],
    bcc: []
}

export const CampaignContext = createContext<CampaignContextModel>({
    campaign: initialCampaignData,
    saveCampaign: () => null
})

export const CampaignProvider = ({ children }: PropsWithChildren) => {
    // ** states
    const [campaign, setCampaign] = useState<NewCampaignModel>(initialCampaignData)

    // ** methods
    const saveCampaign = (data: Partial<NewCampaignModel>) => {
        setCampaign((currentData) => ({
            ...currentData,
            ...data
        }))
    }

    return <CampaignContext.Provider value={{ campaign, saveCampaign }}>{children}</CampaignContext.Provider>
}

export const CampaignConsumer = CampaignContext.Consumer
