import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from 'src/components/shared/Container'
import Meta from 'src/components/shared/Meta'
import OverviewList from 'src/components/new-enrichment/overview/List'

export default function EnrichmentBulkPage() {
    const params = useParams()
    const bulkId = +params.id! as number

    return (
        <Box>
            <Meta title='Bulks Infos | Enrichment Engine | CUFinder' />
            <Container overflow='hidden' bgcolor='white' border={1} borderColor='newNeutral.light5' borderRadius={1}>
                <OverviewList bulkId={bulkId} />
            </Container>
        </Box>
    )
}
