// ** react imports
import { ReactNode } from 'react'

// ** types
import { NavGroup, NavLink } from 'src/@core/layouts/types'

interface Props {
    navGroup?: NavGroup
    children: ReactNode
}

const CanViewNavGroup = (props: Props) => {
    // ** props
    const { children, navGroup } = props

    // ** methods
    const checkForVisibleChild = (arr: NavLink[] | NavGroup[]): boolean => {
        return arr.some((i: NavGroup) => {
            if (i.children) {
                return checkForVisibleChild(i.children)
            }

            return true // ability?.can(i.action, i.subject)
        })
    }

    const canViewMenuGroup = (item: NavGroup) => {
        const hasAnyVisibleChild = item.children && checkForVisibleChild(item.children)

        return hasAnyVisibleChild
    }

    return navGroup && canViewMenuGroup(navGroup) ? <>{children}</> : null
}

export default CanViewNavGroup
