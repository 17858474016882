// ** react imports
import { FC } from 'react'

// ** mui imports
import Box, { BoxProps } from '@mui/material/Box'

// ** third party imports
import { Icon as IconifyIcon, IconProps as IconifyProps } from '@iconify/react'

type RequiredIconProps = Pick<IconifyProps, 'icon' | 'fontSize' | 'style'>
type RequiredBoxProps = Pick<BoxProps, 'sx'>
export type IconProps = RequiredBoxProps &
    RequiredIconProps & {
        iconProps?: Omit<IconifyProps, 'icon' | 'fontSize' | 'style'>
    }

const Icon: FC<IconProps> = ({ icon, fontSize = 20, ...props }) => {
    return (
        <Box component={IconifyIcon} className='iconify' {...props} icon={icon} style={{ ...props.style, fontSize }} />
    )
}

export default Icon
