import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from 'src/components/Button'
import Icon from 'src/components/Icon'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationsDropdown from 'src/@core/layouts/components/shared-components/NotificationsDropdown'
import { Settings } from 'src/@core/context/settingsContext'
import useUser from 'src/store/user'
import { connect, disconnect, onGetCredits } from 'src/services/socket/credits'

interface Props {
    hidden: boolean
    settings: Settings
    saveSettings: (values: Partial<Settings>) => void
}

const intl = new Intl.NumberFormat('en-US')

const AppBarContent = ({ settings }: Props) => {
    const credits = useUser((user) => user.user?.credits || 0)
    const updateCredits = useUser((user) => user.updateCredits)
    useEffect(() => {
        connect(() => {
            onGetCredits((newCredits) => {
                updateCredits(newCredits)
            })
        })
        return () => {
            disconnect()
        }
    }, [])

    return (
        <Stack direction='row' alignItems='center' columnGap={2}>
            <Stack
                direction='row'
                alignItems='center'
                gap={3}
                border={1}
                borderColor='newNeutral.light5'
                borderRadius={5}
                py={2}
                px={4}
            >
                <img src='/images/new-theme/enrichment/coins.png' alt='credits' style={{ width: '25px' }} />
                <Typography variant='newLabelSm' color='newNeutral'>
                    {intl.format(credits)}
                </Typography>
            </Stack>
            <Button
                color='secondary'
                href='/lists'
                startIcon={<Icon icon='mdi:clipboard-list-outline' />}
                size='small'
                sx={{ minWidth: '0!important', width: '6rem' }}
            >
                lists
            </Button>
            <Button
                id='appbar-upgrade-btn'
                href='/plans'
                color='success'
                startIcon={<Icon icon='carbon:upgrade' />}
                size='small'
                sx={{ minWidth: '0!important', width: '6rem' }}
            >
                upgrade
            </Button>
            <NotificationsDropdown settings={settings} />
            <UserDropdown settings={settings} />
        </Stack>
    )
}

export default AppBarContent
