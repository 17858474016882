import { Controller, type Control } from 'react-hook-form'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Icon from 'src/components/shared/Icon'
import AutoComplete from 'src/components/shared/AutoComplete'
import Tooltip from 'src/components/shared/Tooltip'
import type { Input, EnrichForm } from 'src/types/enrichment'

type Props = BoxProps &
    Input & {
        loading?: boolean
        index: number
        control: Control<EnrichForm>
    }

export default function EnrichInput({ id, title, options = [], required, index, control, loading, ...rest }: Props) {
    return (
        <Box {...rest}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Typography component='h4' variant='newLabelMd' color='newNeutral'>
                    {title}
                    <Tooltip
                        title={`Select ${title} base on your uploaded bulk file.`}
                        contentSx={{ display: 'inline-block', ml: 2, mb: -1 }}
                    >
                        <Icon
                            icon='solar:danger-circle-bold'
                            color='neuNeutral'
                            size='md'
                            style={{ display: 'flex' }}
                        />
                    </Tooltip>
                </Typography>
                <Typography component='span' variant='newBodySm' color='newNeutral.light3'>
                    ({required ? 'Required' : 'Optional'})
                </Typography>
            </Stack>
            <Controller
                control={control}
                name={`inputs.${index}.value`}
                rules={{ required: required ? 'field is required' : false }}
                render={({ field, fieldState: { error } }) => (
                    <AutoComplete
                        {...field}
                        onChange={(_, newVal) => {
                            field.onChange(newVal)
                        }}
                        variant='outlined'
                        size='sm'
                        color='newEnrichment'
                        bgcolor='white'
                        borderColor='newNeutral.light4'
                        loading={loading}
                        options={options}
                        placeholder='Select'
                        error={!!error}
                        helperText={error?.message}
                        sx={{ mt: 4 }}
                    />
                )}
            />
        </Box>
    )
}
