// ** redux imports
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

// // ** services import
// import AuthService from 'src/services/auth/Auth.service';

// ** models imports
import { UserModel } from 'src/shared/models'

// ** types imports
import { AuthStateModel } from './types'

// ** configs imports
import authConfigs from 'src/configs/auth'

/**
 * Use to set user data
 */
export const setUserInStore = (state: AuthStateModel, action: PayloadAction<UserModel>) => {
    state.auth.user = action.payload
}

/**
 * Use to set user data
 */
export const updateUserInStore = (state: AuthStateModel, action: PayloadAction<Partial<UserModel>>) => {
    const newUser = Object.assign({}, state.auth.user, action.payload)
    state.auth.user = newUser
    window.localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
}

/**
 * Use to change forget password step
 */
export const getChangeForgetPasswordStep = (state: AuthStateModel, action: PayloadAction<number>) => {
    state.auth.forgetPasswordStep = action.payload
}

/**
 * Use to logout the user
 */
export const getLogoutUser = (state: AuthStateModel, action: PayloadAction) => {
    state.auth.user = null
    window.localStorage.removeItem(authConfigs.storageTokenKeyName)
    window.localStorage.removeItem(authConfigs.storageUserKeyName)
}
