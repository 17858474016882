// ** shared imports
import { UserStateModel, UserStateActions } from 'src/shared/types/store'

// ** configs imports
import authConfigs from 'src/configs/auth'

// ** third party imports
import { create } from 'zustand'

import { PlanPriority } from 'src/shared/types/services'

const useUser = create<UserStateModel & UserStateActions>((set, get) => ({
    // state
    forgetPasswordStep: 1,
    user: null,
    // getters
    isFreePlan: () => {
        const user = get()
        return user.user?.plan.priority === PlanPriority.FREE
    },
    // actions
    setUser: async (newUser) => {
        localStorage.setItem(authConfigs.storageTokenKeyName, newUser.token)
        localStorage.setItem(authConfigs.storageApiKeyName, newUser.api_key)
        localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
        return set(() => ({ user: newUser }))
    },
    updateUser: (newUser) => set((state) => ({ user: { ...state.user!, ...newUser } })),
    updateCredits: (newCredits: number) => {
        const currUser = get().user
        if (currUser) {
            const newUser = { ...currUser, credits: newCredits }
            localStorage.setItem(authConfigs.storageUserKeyName, JSON.stringify(newUser))
            return set(() => ({ user: newUser }))
        }
    },
    updateForgetPasswordStep: (newStep) => set(() => ({ forgetPasswordStep: newStep })),
    logout: () => {
        window.localStorage.removeItem(authConfigs.storageTokenKeyName)
        window.localStorage.removeItem(authConfigs.storageUserKeyName)
        window.localStorage.removeItem(authConfigs.storageApiKeyName)
        return set(() => ({ user: null }))
    }
}))

export default useUser
