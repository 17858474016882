import Box, { type BoxProps } from '@mui/material/Box'
import useColor from 'src/hooks/useColor'

type Props = Omit<BoxProps, 'color'> & {
    size?: number
    space?: number
    color?: string
}

export default function DottedBackground({
    size = 4,
    space = 25,
    color = 'newNeutral.light4',
    children,
    sx,
    ...rest
}: Props) {
    const parsedColor = useColor(color)

    return (
        <Box
            sx={{
                backgroundImage: `radial-gradient(circle at center center,${parsedColor} ${size}px,transparent 0)`,
                backgroundSize: `${space}px ${space}px`,
                backgroundRepeat: 'repeat',
                ...sx
            }}
            {...rest}
        >
            {children}
        </Box>
    )
}
