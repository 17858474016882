import { type ReactNode } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card, { type CardProps } from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Icon from 'src/components/shared/Icon'

export type Overview = {
    title: string
    date: null | string
    description?: string
    icon?: string
    iconColor?: string
    iconBoxColor?: string
    credits?: number
    children?: ReactNode
}
type Props = CardProps & Overview

export default function OverviewCard({
    title,
    date,
    description,
    icon = 'solar:pen-new-square-outline',
    iconColor = 'newPrimary',
    iconBoxColor = 'newPrimary.light4',
    credits = 0,
    children,
    sx,
    ...rest
}: Props) {
    const showBottomSection = !!(children || description)

    return (
        <Card variant='outlined' sx={{ px: 6, py: 4, bgcolor: 'white', ...sx }} {...rest}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={6}>
                <Stack direction='row' alignItems='center' gap={3}>
                    <Stack
                        width={45}
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        flexShrink={0}
                        bgcolor={iconBoxColor}
                        borderRadius={1}
                        sx={{ aspectRatio: '1 / 1' }}
                    >
                        <Icon icon={icon} color={iconColor} size='lg' />
                    </Stack>
                    <Box>
                        <Typography component='p' variant='newLabelLg' color='newNeutral'>
                            {title}
                        </Typography>
                        {!!date && (
                            <Typography component='p' variant='newBodySm' color='newNeutral.light2' mt={2}>
                                {date}
                            </Typography>
                        )}
                    </Box>
                </Stack>
                {!!credits && (
                    <Box px={2} py={1} border={1} borderColor='newNeutral.light5' borderRadius={10}>
                        <img src='/images/new-theme/enrichment/coins.png' alt='credits' style={{ width: '16px' }} />
                        <Typography component='span' variant='newLabelSm' color='newNeutral' ml={2}>
                            {credits}
                        </Typography>
                    </Box>
                )}
            </Stack>
            {showBottomSection && (
                <>
                    <Divider sx={{ my: 3, borderColor: 'newNeutral.light5' }} />
                    <Box>
                        {!!description && (
                            <Typography component='p' variant='newBodySm' color='newNeutral.light2'>
                                {description}
                            </Typography>
                        )}
                        {children}
                    </Box>
                </>
            )}
        </Card>
    )
}
