// ** layouts imports
import { Skin } from 'src/@core/layouts/types'

// ** mui imports
import { PaletteMode } from '@mui/material'

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
    // ** Vars
    const lightColor = '26, 26, 26' // '76, 78, 100';
    const darkColor = '234, 234, 255'
    const mainColor = mode === 'light' ? lightColor : darkColor

    const defaultBgColor = () => {
        if (skin === 'bordered' && mode === 'light') {
            return '#FFF'
        } else if (skin === 'bordered' && mode === 'dark') {
            return '#30334E'
        } else if (mode === 'light') {
            return '#F7F7F9'
        } else return '#282A42'
    }

    return {
        customColors: {
            dark: `rgb(${darkColor})`,
            main: `rgb(${mainColor})`,
            light: `rgb(${lightColor})`,
            darkBg: '#282A42',
            lightBg: '#F7F7F9',
            bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42', // Same as palette.background.default but doesn't consider bordered skin
            tooltipBg: mode === 'light' ? '#262732' : '#464A65',
            tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B'
        },
        common: {
            black: '#000',
            white: '#FFF'
        },
        mode: mode,
        primary: {
            light: '#787EFF',
            main: '#2667FF', // '#666CFF',
            dark: '#2667FF', // '#5A5FE0',
            contrastText: '#FFF'
        },
        secondary: {
            light: '#7F889B',
            main: '#6D788D',
            dark: '#606A7C',
            contrastText: '#FFF'
        },
        success: {
            light: '#83E542',
            main: '#22C55E',
            dark: '#22C55E',
            contrastText: '#FFF'
        },
        error: {
            light: '#FF625F',
            main: '#F43F5E',
            dark: '#E04440',
            contrastText: '#FFF'
        },
        warning: {
            light: '#FDBE42',
            main: '#EAB308',
            dark: '#DF9F23',
            contrastText: '#FFF'
        },
        info: {
            light: '#40CDFA',
            main: '#3B82F6',
            dark: '#21AEDB',
            contrastText: '#FFF'
        },
        prospect_engine: {
            main: '#ff554d'
        },
        enrichment_engine: {
            main: '#01077a'
        },
        business_directory_engine: {
            main: '#D95D39'
        },
        hr_engine: {
            main: '#f1b419'
        },
        email_finder_engine: {
            main: '#5e1779'
        },
        phone_finder_engine: {
            main: '#A6261D'
        },
        grey: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '#EEEEEE',
            300: '#E0E0E0',
            400: '#BDBDBD',
            500: '#9E9E9E',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#D5D5D5',
            A200: '#1A1A1A',
            A400: '#616161',
            A700: '#303030'
        },
        text: {
            primary: `rgba(${mainColor}, 1)`,
            secondary: `rgba(${mainColor}, 0.68)`,
            disabled: `rgba(${mainColor}, 0.38)`
        },
        divider: `rgba(${mainColor}, 0.12)`,
        background: {
            paper: mode === 'light' ? '#FFF' : '#30334E',
            default: defaultBgColor()
        },
        action: {
            active: `rgba(${mainColor}, 0.54)`,
            hover: `rgba(${mainColor}, 0.05)`,
            hoverOpacity: 0.05,
            selected: `rgba(${mainColor}, 0.08)`,
            disabled: `rgba(${mainColor}, 0.26)`,
            disabledBackground: `rgba(${mainColor}, 0.12)`,
            focus: `rgba(${mainColor}, 0.12)`
        },
        newPrimary: {
            light5: '#EEEDFD',
            light4: '#C9C7FA',
            light3: '#AFACF7',
            light2: '#8A86F4',
            light1: '#746FF1',
            light: '#8A86F4',
            main: '#514BEE',
            dark: '#3A35A9',
            dark1: '#4A44D9',
            dark2: '#3A35A9',
            dark3: '#2D2983',
            dark4: '#222064',
            dark5: '#222064',
            contrastText: '#fff'
        },
        newSecondary: {
            light5: '#FEEEE9',
            light4: '#FCC9B9',
            light3: '#FAAF98',
            light2: '#F88A68',
            light1: '#F6744B',
            light: '#F88A68',
            main: '#F4511E',
            dark: '#AD3A15',
            dark1: '#DE4A1B',
            dark2: '#AD3A15',
            dark3: '#862D11',
            dark4: '#66220D',
            dark5: '#66220D',
            contrastText: '#fff'
        },
        newSuccess: {
            light5: '#ECF8EC',
            light4: '#C3EBC4',
            light3: '#A6E1A8',
            light2: '#7DD380',
            light1: '#64CA67',
            light: '#7DD380',
            main: '#3DBD41',
            dark: '#2B862E',
            dark1: '#38AC3B',
            dark2: '#2B862E',
            dark3: '#226824',
            dark4: '#1A4F1B',
            dark5: '#1A4F1B',
            contrastText: '#fff'
        },
        newInfo: {
            light5: '#E9F5FE',
            light4: '#BADEFB',
            light3: '#99CFF9',
            light2: '#6AB9F7',
            light1: '#4DABF5',
            light: '#6AB9F7',
            main: '#2196F3',
            dark: '#176BAD',
            dark1: '#1E89DD',
            dark2: '#176BAD',
            dark3: '#125386',
            dark4: '#0E3F66',
            dark5: '#0E3F66',
            contrastText: '#fff'
        },
        newError: {
            light5: '#EEEDFD',
            light4: '#FDEAE9',
            light3: '#F49D98',
            light2: '#EE7168;',
            light1: '#EB554B',
            light: '#EE7168',
            main: '#E62B1E',
            dark: '#A31F15',
            dark1: '#D1271B',
            dark2: '#A31F15',
            dark3: '#7F1811',
            dark4: '#61120D',
            dark5: '#61120D',
            contrastText: '#fff'
        },
        newWarning: {
            light5: '#FFF9E6',
            light4: '#FFECB2',
            light3: '#FFE28D',
            light2: '#FFD559',
            light1: '#FFCD39',
            light: '#FFD559',
            main: '#FFC107',
            dark: '#B58905',
            dark1: '#E8B006',
            dark2: '#B58905',
            dark3: '#8C6A04',
            dark4: '#6B5103',
            dark5: '#6B5103',
            contrastText: '#fff'
        },
        newNeutral: {
            light5: '#E8E8E8',
            light4: '#B7B7B7',
            light3: '#959595',
            light2: '#646464',
            light1: '#464646',
            light: '#646464',
            main: '#181818',
            dark: '#111111',
            dark1: '#161616',
            dark2: '#111111',
            dark3: '#0D0D0D',
            dark4: '#0A0A0A',
            dark5: '#0A0A0A',
            contrastText: '#fff'
        },
        newPastelGold: {
            light5: '#F5F2E6',
            light4: '#FDEDBB',
            light3: '#D1C18D',
            light2: '#BDA55A',
            light1: '#B09339',
            light: '#BDA55A',
            main: '#9C7808',
            dark: '#6F5506',
            dark1: '#8E6D07',
            dark2: '#6F5506',
            dark3: '#564204',
            dark4: '#423203',
            dark5: '#423203',
            contrastText: '#fff'
        },
        newPastelCyan: {
            light5: '#E6FCFB',
            light4: '#CFF1EF',
            light3: '#8AF1ED',
            light2: '#54EBE4',
            light1: '#33E7DF',
            light: '#54EBE4',
            main: '#00E1D7',
            dark: '#00A099',
            dark1: '#00CDC4',
            dark2: '#00A099',
            dark3: '#007C76',
            dark4: '#005F5A',
            dark5: '#005F5A',
            contrastText: '#fff'
        },
        newPastelGreen: {
            light5: '#ECF6D6',
            light4: '#D9EBB0',
            light3: '#C6E18A',
            light2: '#ACD354',
            light1: '#9CCB33',
            light: '#ACD354',
            main: '#83BE00',
            dark: '#5D8700',
            dark1: '#77AD00',
            dark2: '#5D8700',
            dark3: '#486900',
            dark4: '#375000',
            dark5: '#375000',
            contrastText: '#fff'
        },
        newPastelRed: {
            light5: '#F7E7E6',
            light4: '#FFDDDB',
            light3: '#FFB4AF',
            light2: '#FF928A',
            light1: '#FF7D73',
            light: '#FF928A',
            main: '#FF5C50',
            dark: '#B54139',
            dark1: '#E85449',
            dark2: '#B54139',
            dark3: '#8C332C',
            dark4: '#6B2722',
            dark5: '#6B2722',
            contrastText: '#fff'
        },
        newPastelPurple: {
            light5: '#F4EEFF',
            light4: '#DCCCFF',
            light3: '#CBB3FF',
            light2: '#B390FF',
            light1: '#A47AFF',
            light: '#B390FF',
            main: '#8D59FF',
            dark: '#643FB5',
            dark1: '#8051E8',
            dark2: '#643FB5',
            dark3: '#4E318C',
            dark4: '#3B256B',
            dark5: '#3B256B',
            contrastText: '#fff'
        },
        newPastelBlue: {
            light5: '#EEF8FF',
            light4: '#BBE1FD',
            light3: '#B3DFFF',
            light2: '#90D0FF',
            light1: '#7AC7FF',
            light: '#90D0FF',
            main: '#59B9FF',
            dark: '#3F83B5',
            dark1: '#51A8E8',
            dark2: '#3F83B5',
            dark3: '#31668C',
            dark4: '#254E6B',
            dark5: '#254E6B',
            contrastText: '#fff'
        },
        newSurface: {
            light5: '#FFFFFF',
            light4: '#FFFFFF',
            light3: '#F9F9F9',
            light2: '#F8F8FF',
            light1: '#F8F8FF',
            light: '#F8F8FF',
            main: '#E8E8E8',
            dark: '#B7B7B7',
            dark1: '#B7B7B7',
            dark2: '#B7B7B7',
            dark3: '#959595',
            dark4: '#646464',
            dark5: '#464646',
            contrastText: '#181818'
        },
        newEnrichment: {
            light5: '#8086F9',
            light4: '#676DE0',
            light3: '#4E54C7',
            light2: '#272DA0',
            light1: '#0E1487',
            light: '#272DA0',
            main: '#01077a',
            dark: '#000061',
            dark1: '#00006D',
            dark2: '#000061',
            dark3: '#000054',
            dark4: '#000047',
            dark5: '#00003A',
            contrastText: '#fff'
        }
    }
}

export default DefaultPalette
