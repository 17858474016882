import { Fragment } from 'react'
import { useQuery } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import CircularProgress from 'src/components/shared/CircularProgress'
import NoResult from 'src/components/shared/NoResult'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import DottedBackground from 'src/components/shared/DottedBackground'
import ViewportManager from 'src/components/shared/ViewportManager'
import OverviewCard, { type Overview } from 'src/components/new-enrichment/overview/Card'
import { getEnrichments } from 'src/services/new-enrichment'
import services from 'src/data/enrichment/services'

export enum Type {
    IMPORT = 'import',
    ENRICH = 'enrich'
}
type Props = Omit<BoxProps, 'color'> & {
    bulkId: number
}

const intl = Intl.NumberFormat('en-US')

export default function OverviewList({ bulkId, ...rest }: Props) {
    const { isFetching: enrichesLoading, data: enriches } = useQuery({
        initialData: [],
        queryKey: ['get-bulk-enriches', bulkId],
        queryFn: async () => {
            const { items } = await getEnrichments({ bulkId })
            const firstItem = items[0] || {}
            const { type } = services.find((service) => service.code === firstItem.serviceCode)! || {}
            const firstOverview: Overview = {
                title: type === 'company' ? 'Import list of companies' : 'Import list of people',
                description: 'File import',
                date: firstItem.startDate,
                icon: type === 'company' ? 'solar:buildings-3-bold-duotone' : 'ph:users-three-duotone',
                iconColor: type === 'company' ? 'newPastelPurple' : 'newPastelRed',
                iconBoxColor: type === 'company' ? 'newPastelPurple.light4' : 'newPastelRed.light4'
            }
            const overviews: Overview[] = items.flatMap((enrich) => {
                const { type, title, credits } = services.find((service) => service.code === enrich.serviceCode)!
                return [
                    {
                        title: title,
                        description: `${intl.format(enrich.rowCounts)} rows`,
                        date: enrich.endDate,
                        // credits: credits,
                        icon: 'solar:check-circle-outline',
                        iconColor: 'newPastelGreen',
                        iconBoxColor: 'newPastelGreen.light5'
                    }
                ]
            })
            return [firstOverview, ...overviews]
        }
    })

    return (
        <Box p={5} overflow='auto' className='custom-scrollbar' {...rest}>
            <DottedBackground color='newNeutral.light5' space={10} size={1} p={2} minHeight='75vh'>
                <ViewportManager p={2} sidebarPosition='sticky' fullScreenSx={{ bgcolor: 'white' }}>
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        {enrichesLoading && <CircularProgress variant='indeterminate' size={120} />}
                        {!!(!enrichesLoading && !enriches.length) && <NoResult />}
                        {!!(!enrichesLoading && enriches.length) && (
                            <List
                                sx={{
                                    maxWidth: { xs: '100%', md: 500 },
                                    mx: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    gap: 0
                                }}
                            >
                                {enriches.map((item, idx) => (
                                    <Fragment key={idx}>
                                        <ListItem sx={{ p: 0 }}>
                                            <OverviewCard {...item} sx={{ width: 1 }} />
                                        </ListItem>
                                        {idx < enriches.length - 1 && (
                                            <ListItem sx={{ p: 0 }}>
                                                <img
                                                    src='/images/new-theme/others/arrow-black-down.png'
                                                    alt='arrow'
                                                    style={{ margin: '0 auto', height: '60px' }}
                                                />
                                            </ListItem>
                                        )}
                                    </Fragment>
                                ))}
                            </List>
                        )}
                    </Box>
                </ViewportManager>
            </DottedBackground>
        </Box>
    )
}
